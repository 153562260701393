import {Component, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {Student} from "../../shared/models/student";
import {UserService} from "../../shared/services/user.service";
import {PartielNotificationService} from "../../shared/services/partielNotification.service";
import {environment} from "../../../environments/environment";

declare var swal: any;
declare var jQuery;

@Component({
  templateUrl: 'menu.component.html',
  styleUrls: [],

})
export class MenuComponent implements OnInit {

  loggedUser: Student = new Student();

  baseURL: string;
  busy: Subscription;

  year_university_notification_number: number;


  ngOnInit() {
    this.loggedUser = this.userService.loggedUser;
    /*
    this.partielNotificationService.getNewNotifications()
      .subscribe(
        (data) => {
          this.year_university_notification_number = Utils.getNotificationBySubSection(1, data);
        },
        (error) => {

        }
      );
      */
  }

  constructor(public userService: UserService, private  partielNotificationService: PartielNotificationService) {
    this.baseURL = environment.baseUrl;

  }


  openModalReclamation() {
    const baseContext = this;

    console.log("modal");

    jQuery("#modal_form_vertical").modal();
  }


}
