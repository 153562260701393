import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {BoiteReclamationComponent} from "./boite-reclamation/boite-reclamation.component";
import {MessagesModule} from "./boite-messages/messages.module";

export function loadMessageModule() {
  return MessagesModule
}

export const routes: Routes = [
  {
    path: '',
    children: [

      {
        path: 'reclamation',
        component: BoiteReclamationComponent
      },
      {
        path: 'messages',
        loadChildren: loadMessageModule
      }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SupportModuleRouting {
}
