import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from "@angular/core";
import {SharedModule} from "../shared/shared.module";
import {EcosComponent} from "./ecos/ecos.component";
import {InscriptionsModuleRouting} from "./inscriptions.routing";
import {MenuComponent} from "./menu/menu.component";
import {InscriptionsUniversityYearComponent} from "./university-year/inscriptions-university-year.component";
import {SessionStageComponent} from "./stage/session-stage/session-stage.component";
import {GroupeChoiceComponent} from "./stage/groupe-choice/groupe-choice.component";
import {ServiceChoiceComponent} from "./stage/service-choice/service-choice.component";
import { PostGraduateTrainingComponent } from './post-graduate-training/post-graduate-training.component';
import { OptionalUnitChoiceComponent } from './optional-unit-choice/optional-unit-choice.component';
import { MiniSeminarComponent } from './mini-seminar/mini-seminar.component';


/**
 * Created by AHMED on 03/08/2017.
 */
@NgModule({
  imports: [
    InscriptionsModuleRouting,
    SharedModule,
  ],
  declarations: [
    MenuComponent,
    EcosComponent,
    InscriptionsUniversityYearComponent,
    SessionStageComponent,
    GroupeChoiceComponent,
    ServiceChoiceComponent,
    PostGraduateTrainingComponent,
    OptionalUnitChoiceComponent,
    MiniSeminarComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: []
})
export class InscriptionsModule {
}
