import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {StageService} from "../../shared/services/stage.service";
import {Subscription} from "rxjs";
import {Critere_Etudiant} from "../../shared/models/stage/Critere_Etudiant";
import {Evaluation_Critere_Etudiant} from "../../shared/models/stage/Evaluation_Critere_Etudiant";
import {Critere} from "../../shared/models/stage/Critere";
import {Evaluation_Critere} from "../../shared/models/stage/Evaluation_Critere";
import {Evaluation} from "../../shared/models/stage/Evaluation";

@Component({
  selector: 'app-details-evaluation',
  templateUrl: './details-evaluation.component.html',
  styleUrls: ['./details-evaluation.component.css']
})
export class DetailsEvaluationComponent implements OnInit {
  evaluation_id: number;
  busy: Subscription;
  criteres: Critere[] = [];
  evaluations_criteres: Evaluation_Critere[] = [];
  evaluation: Evaluation;

  constructor(private router: Router, private route: ActivatedRoute, private stageService: StageService) {
  }

  ngOnInit() {
    this.evaluation = this.stageService.evaluation;
    if (!this.evaluation || this.evaluation.eval_etudiant === 0 || !this.evaluation.note || !this.evaluation.evaluations_criteres) {
      this.router.navigateByUrl('/enseignement/stage');
      return;
    }
    for (const ec of this.evaluation.evaluations_criteres) {
      ec.critere.significations = [];
      for (const signfication of ec.critere.signficiations_criteres) {
        ec.critere.significations.push(signfication.description);
      }
    }
  }

}
