import {StorageService} from "app/shared/services/storage.service";
import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Conversation} from "../models/Conversation";
import {UserService} from "./user.service";
import {environment} from "../../../environments/environment";

@Injectable()
export class ConversationService extends GenericService {

  constructor(private http: HttpClient, private storageService: StorageService, private userService: UserService) {
    super();
  }

  getStudentAllConversations() {
    const url = environment.baseUrl + '/conversation/student';
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.get<Conversation[]>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    );
  }

  getConversationById(id_conversation: number) {
    const url = environment.baseUrl + '/conversation/' + id_conversation;
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.get<Conversation>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  getConversationMessages(id_conversation: number) {
    const url = environment.baseUrl + '/conversation/' + id_conversation + '/message';
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.get(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    );
  }

  startConversation(id_student: number, id_admin: number, content?: string, topic?: string) {
    const url = environment.baseUrl + '/conversation/start';
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.post<any>(url, {
      id_Student: id_student,
      id_Admin: id_admin,
      content: content,
      topic: topic
    }, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  startConversationUnregistered(isTeacher: number, email: string, nom: string, id_admin: number, CIN?: string, passport?: string, content?: string, topic?: string) {
    const url = environment.baseUrl + '/conversation/startUnregistered';
    console.log(nom);
    return this.http.post<any>(url, {
      isTeacher: isTeacher,
      CIN: CIN,
      passport: passport,
      nom: nom,
      email: email,
      id_Admin: id_admin,
      content: content,
      topic: topic
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  addConversationMessage(conversation: Conversation, content: string) {
    const url = environment.baseUrl + '/conversation/' + conversation.id_Conversation + '/message/add';
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.post<Conversation>(url, {
      id_Student: conversation.id_Student,
      id_Admin: null,
      content: content
    }, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    );
  }

  getConversationByStatus(status: number) {
    const url = environment.baseUrl + '/conversation/student/status/' + status;
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.get<Conversation[]>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  updateConversationStatus(conversation: Conversation, status: number) {
    const url = environment.baseUrl + '/conversation/' + conversation.id_Conversation + '/status/{status}';
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.post(url, {
      status: status
    }, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  setConversationViewed(conversation: Conversation) {
    const url = environment.baseUrl + '/conversation/' + conversation.id_Conversation + '/viewed';
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.put(url, {}, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  getConversationsCount() {
    const url = environment.baseUrl + '/conversation/student/count';
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.get<any>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }
}
