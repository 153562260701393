import {Component, OnInit} from "@angular/core";
import {Subscription} from "rxjs/Subscription";
import {StageService} from "../../../shared/services/stage.service";
import {ActivatedRoute, Router} from "@angular/router";
import {S_Groupe} from "../../../shared/models/stage/S_Groupe";

declare var swal: any;
declare var jQuery;

@Component({
  templateUrl: 'service-choice.component.html',
  styleUrls: [],
})
export class ServiceChoiceComponent implements OnInit {

  busy: Subscription;
  sessionStageId: number;
  sgroupes: S_Groupe[] = [];

  ngOnInit() {
    console.log("Session Stage");
    const baseContext = this;
    this.sessionStageId = parseInt(this.route.snapshot.paramMap.get('sessionstageId'));

    this.busy = this.stageService.getMultipleChoiceServices(this.sessionStageId)
      .subscribe(
        (data) => {
          this.sgroupes = data;
          setTimeout(function () {
            for (let i = 0; i < baseContext.sgroupes.length; i++) {
              baseContext.initRadioBox('.radioBox-service', i);
            }
          }, 20);
        },
        (error) => {

        }
      )


  }

  submit() {
    console.log(this.sgroupes);
    this.busy = this.stageService.studentChoiceService(this.sessionStageId, this.sgroupes)
      .subscribe(
        (data) => {
          swal({
            title: "Succès!",
            text: "Choix de l'étudiant effectué avec succées",
            confirmButtonColor: "#66BB6A",
            type: "success"
          });
          this.router.navigate(['/inscription/stage/session-stage']);
        }
      )
  }

  initRadioBox(className, index: number) {

    const baseContext = this;
    jQuery(className + "-" + index).uniform({
      radioClass: 'choice'
    });

    jQuery(className + "-" + index).on("change", function () {
      const indexService = baseContext.sgroupes[index].services.map(
        function (x) {
          return x.service_id
        }
      ).indexOf(parseInt(jQuery(this).val()));
      baseContext.sgroupes[index].services[indexService].choice = 1;


      let s = 2;
      for (let i = 0; i < baseContext.sgroupes[index].services.length; i++) {
        if (indexService !== i) {
          baseContext.sgroupes[index].services[i].choice = s;
          s++;
        }
      }
    });


  }


  constructor(private stageService: StageService, private route: ActivatedRoute,
              private router: Router) {
  }
}
