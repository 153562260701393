/**
 * Created by AHMED on 03/08/2017.
 */
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {EvaluationStageComponent} from "./evaluation-stage/evaluation-stage.component";


export const routes: Routes = [
  {
    path: 'evaluation-stage/:evaluation_id',
    component: EvaluationStageComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EvaluationsModuleRouting {
}
