import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EvaluationStageComponent} from './evaluation-stage/evaluation-stage.component';
import {EvaluationsModuleRouting} from "./evaluations.routing";
import {FormsModule} from "@angular/forms";
import {SharedModule} from "../shared/shared.module";

@NgModule({
  imports: [
    EvaluationsModuleRouting,
    CommonModule, FormsModule,    SharedModule
  ],
  declarations: [EvaluationStageComponent]
})
export class EvaluationsModule {
}
