/**
 * Created by Abbes on 21/08/2017.
 */
import {Component, Input, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {SectionValidation} from "../../shared/models/section-validation";
import {Student} from "app/shared/models/student";
import {Utils} from "../../shared/utils";
import {StudentFileService} from "../../shared/services/student-file.service";
declare var jQuery: any;
declare var swal: any;
@Component({
  selector: 'validation-note',
  templateUrl: 'validation-note.component.html',
  styleUrls: [],
})
export class ValidationNoteComponent implements OnInit {


  @Input()
  sectionId: number;
  @Input()
  student: Student;


  statusSection: SectionValidation;


  ngOnInit() {
    this.statusSection = Utils.getStatusSection(this.student.validations, this.sectionId);
  }

}


