import {Component, OnInit} from '@angular/core';
import {StageService} from "../../shared/services/stage.service";
import {ResultGroupe} from "../../shared/models/stage/ResultGroupe";
import {Subscription} from "rxjs";
import {Periode} from "../../shared/models/stage/Periode";
import {Evaluation} from "../../shared/models/stage/Evaluation";
import {Critere} from "../../shared/models/stage/Critere";
import {CritereLevel} from "../../shared/models/stage/CritereLevel";
import {ActivatedRoute, Route, Router} from "@angular/router";
import {CompetanceClinique} from "../../shared/models/stage/CompetanceClinique";
import {CriterePortfolio} from "../../shared/models/stage/CriterePortfolio";

@Component({
  selector: 'app-result-stages',
  templateUrl: './result-stages.component.html',
  styleUrls: ['./result-stages.component.css']
})
export class ResultStagesComponent implements OnInit {
  resultGroupes: ResultGroupe[] = [];

  busy: Subscription;
  periodes: Periode[];
  evaluations: Evaluation[];
  array_rows: Array_Row[] = [];
  periodesNumber: number;
  criteres_levels: CritereLevel[] = [];
  selectedPeriode: Periode;

  criteresEcos: CompetanceClinique[] = [];
  criteresPortfolio: CriterePortfolio[] = [];

  constructor(private stageService: StageService, private router: Router) {
  }

  ngOnInit() {
    this.getResultGroupesByStudentAndNiveau();
  }


  private getResultGroupesByStudentAndNiveau() {

    this.busy = this.stageService.getResultGroupesByStudentAndNiveau().subscribe((data) => {
      this.resultGroupes = data['resultGroups'];
      this.evaluations = data['evaluations'];
      this.criteresEcos = data['criteresEcos'];
      this.criteresPortfolio = data['criteresPortfolio'];
      this.criteres_levels = data['criteres_levels'];
      this.periodes = this.resultGroupes.map(smth => smth.periode);
      this.periodes.forEach((periode, i) => {
        i += 1;
        periode.label = "Période " + i;
      });
      for (const critere_level of this.criteres_levels) {
        const ar = new Array_Row();
        ar.label_critere = critere_level.critere.label;
        ar.critere_id = critere_level.critere.critere_id;
        ar.eval_jury = 0;
        ar.ponderation = critere_level.ponderation;
        this.array_rows.push(ar);
      }
    })
  }

  details(decision: number, i: number) {
    console.log(decision);
    if (decision !== 0) {
      this.selectedPeriode = this.periodes[i];
      this.periodesNumber = i + 1;
      this.stageService.context = this;
      this.router.navigateByUrl('/portfolio/details-result-stages');
    }
  }
}

class Array_Row {
  label_critere = "";
  critere_id = 0;
  ponderation = 0;
  values = [];
  eval_jury: number;
}
