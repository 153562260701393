/**
 * Created by Abbes on 25/08/2017.
 */
export class RegistrationYearUniversity {
  public id_Registration_University: number;
  public start_date: string;
  public end_date: string;
  public description: string;
  public year_university: string;

  isInscriptionPossible: number;
  // 0 not open yet , 1 open , 2 closed , 3 registred ,
}
