import {Component, OnInit} from '@angular/core';
import {SessionExamenService} from "../../shared/services/sessionExamen.service";
import {MoyenneSession} from "../../shared/models/direction_etudes/MoyenneSession";
import {Unite} from "../../shared/models/direction_etudes/unite";

@Component({
  selector: 'app-result-session-examens',
  templateUrl: './result-session-examens.component.html',
  styleUrls: ['./result-session-examens.component.css']
})
export class ResultSessionExamensComponent implements OnInit {

  moyennesSessions: MoyenneSession[];
  moyenneSession: MoyenneSession;
  disciplines: DisciplineResult[] = [];

  constructor(private sessionExamenService: SessionExamenService) {
  }

  ngOnInit() {
    this.getSessionByEtudiant();
  }

  getSessionByEtudiant() {
    this.sessionExamenService.getMoyennesSessionsExamensByStudent().subscribe((data) => {
      this.moyennesSessions = data;
      if (this.moyennesSessions != null && this.moyennesSessions.length > 0) {
        this.moyenneSession = this.moyennesSessions[0];
        this.getDisciplineBySession();
        console.log(this.disciplines);
      }
    })
  }

  getDisciplineBySession() {

    this.moyenneSession.moyennesUnites.forEach(
      unite => {
        unite.moyennesDisciplines.forEach(
          discipline => {
            this.disciplines.push({
              note: discipline.moyenne,
              label: discipline.uniteSessionDiscipline.discipline.titre,
              unite: unite.uniteSession.unite,
              ponderation: discipline.uniteSessionDiscipline.ponderation,
              numbersdisciplines: unite.moyennesDisciplines.length,
              avg: unite.moyenne
            });
          }
        )
      }
    )
  }

}

export class DisciplineResult {
  note: number;
  label: string;
  unite: Unite;
  numbersdisciplines: number;
  ponderation: number;
  avg: number;
}
