import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {StageService} from "../../shared/services/stage.service";
import {Router} from "@angular/router";
import {ResultGroupe} from "../../shared/models/stage/ResultGroupe";
import {Periode} from "../../shared/models/stage/Periode";
import {Evaluation} from "../../shared/models/stage/Evaluation";
import {CritereLevel} from "../../shared/models/stage/CritereLevel";
import {CompetanceClinique} from "../../shared/models/stage/CompetanceClinique";
import {EvaluationCClinique} from "../../shared/models/stage/EvaluationCClinique";
import {CriterePortfolio} from "../../shared/models/stage/CriterePortfolio";
import {EvaluationPortfolio} from "../../shared/models/stage/EvaluationPortfolio";


@Component({
  selector: 'app-details-result-stage',
  templateUrl: './details-result-stage.component.html',
  styleUrls: ['./details-result-stage.component.css']
})
export class DetailsResultStageComponent implements OnInit {
  busy: Subscription;
  context: any;
  resultGroupes: ResultGroupe[] = [];
  selectedPeriodeId: number;
  selectedPeriode: Periode;
  periodesNumber: number;
  array_rows: Array_Row[] = [];
  evaluations: Evaluation[] = [];
  criteres_levels: CritereLevel[] = [];
  periodes: Periode[] = [];
  speriode_labels: string[];
  no_speriodes_needed: number;

  evalsJury: boolean[] = [];

  criteresEcos: CompetanceClinique[] = [];
  criteresPortfolio: CriterePortfolio[] = [];

  constructor(private stageService: StageService, private router: Router) {
    this.context = this.stageService.context;
    if (!this.context) {
      this.router.navigateByUrl('/portfolio/result-stages');
    } else {
      console.log(this.context);
      this.resultGroupes = this.context["resultGroupes"];
      this.selectedPeriodeId = this.context["selectedPeriodeId"];
      this.periodes = this.context["periodes"];
      this.selectedPeriode = this.context["selectedPeriode"];
      this.evaluations = this.context["evaluations"];
      this.criteresEcos = this.context["criteresEcos"];
      this.criteresPortfolio = this.context["criteresPortfolio"];
      this.calculateNotes();
      this.criteres_levels = this.context["criteres_levels"];
      this.periodesNumber = this.context["periodesNumber"];
      this.array_rows = this.context["array_rows"];

      this.initializegrid();
    }
  }

  ngOnInit() {
    // console.log(this.context);
    // console.log(this);
  }

  initializegrid() {
    for (const r of this.resultGroupes) {
      if (!r["evaluations"]) {
        r["evaluations"] = [];
      }
    }
    for (const e of this.evaluations) {
      for (const r of this.resultGroupes) {
        if (e["periode_stage"]["periode_id"] === r["periode_id"]) {
          r["evaluations"].push(e);
        }
      }
    }
    this.speriode_labels = [];
    const baseContext = this;
    // this.resultGroupes.forEach((rg,indexr)=>{
    this.no_speriodes_needed = 0;
    let x = 1;
    this.periodes.slice(0, baseContext.periodesNumber).forEach((periode, index) => {
      periode.no_speriodes = this.resultGroupes[index].evaluations.length;
      baseContext.no_speriodes_needed += periode.no_speriodes;
      for (let j = 1; j <= periode.no_speriodes; j += 1) {
        baseContext.speriode_labels.push("Éval " + x);
        x += 1;
      }
    });

    const arr = [];
    const criteres_count = this.array_rows.length;
    // Here is the initialization of the evaluation criteres of jury
    this.array_rows.forEach((r, index) => {
      r.values = [];
      r.eval_jury = baseContext.resultGroupes[baseContext.periodesNumber - 1].evaluations_criteres[index] ?
        baseContext.resultGroupes[baseContext.periodesNumber - 1].evaluations_criteres[index].value : 0;
    });
    this.resultGroupes.forEach((rg, index) => {
      rg.evaluations.forEach((evaluation, ind) => {
          for (let i = 0; i < criteres_count; i += 1) {
            baseContext.array_rows[i].values.push(
              evaluation.evaluations_criteres[i] && index < baseContext.periodesNumber ?
                evaluation.evaluations_criteres[i].value : " ");
          }
          baseContext.evalsJury.push(false);
        }
      );
    });
    console.log(baseContext.evalsJury);
  }

  getValueByEvaluation(evaluationEcos: EvaluationCClinique, competance_clinique_id: number) {
    if (!evaluationEcos)
      return -1;

    const indexCompetance = evaluationEcos.validations.map(item => {
      return item.competance_clinique_id
    }).indexOf(competance_clinique_id);

    return indexCompetance !== -1 ? evaluationEcos.validations[indexCompetance].value : -1;
  }


  getValidationPortfolio(evaluationPortfolio: EvaluationPortfolio, criterePortfolioId: number) {
    if (!evaluationPortfolio) {
      return null;
    }

    const indexValidation = evaluationPortfolio.validations.map(
      item => {
        return item.critere_portfolio_id;
      }
    ).indexOf(criterePortfolioId);

    if (indexValidation === -1) {
      return null;
    }

    return evaluationPortfolio.validations[indexValidation];
  }

  calculateNotes() {

    this.criteresPortfolio.forEach(
      critere => {
        critere.validations = [];
        this.evaluations.forEach(
          evaluation => {
            critere.validations.push(this.getValidationPortfolio(evaluation.evaluation_portfolio, critere.critere_portfolio_id));
          }
        )
      }
    );

    console.log(this.criteresPortfolio);
    this.criteresEcos.forEach(
      critere => {
        critere.notes = [];
        this.evaluations.forEach(
          evaluation => {
            critere.notes.push(this.getValueByEvaluation(evaluation.ecos_evaluation, critere.competance_clinique_id));
          }
        )
      }
    );
  }
}


class Array_Row {
  label_critere = "";
  critere_id = 0;
  ponderation = 0;
  values = [];
  eval_jury: number;
}
