import {Niveau} from "../niveau";
import {Groupe} from "./Groupe";

export class Session_Stage {
  public session_stage_id: number;
  public nb_p_g: string;
  public niveau: Niveau = new Niveau();
  public niveau_id: number;
  public name: string;
  public date_choice_open: string;
  public date_choice_close: string;
  public date_service_open: string;
  public date_service_close: string;
  public capacity: number;

  public statusChoice: number = 0; // 0 inactive ; 1 choice group ; 2 choice service

  public groupes: Groupe[] = [];

}






