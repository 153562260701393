import {Component, OnInit} from "@angular/core";

import {ConversationService} from "../../../shared/services/conversation.service";
import {Conversation} from "../../../shared/models/Conversation";
import {Subscription} from "rxjs/Subscription";
import {environment} from "../../../../environments/environment";

declare let swal;
declare let jQuery;

@Component({
  templateUrl: 'all-messages.component.html',
  styleUrls: [],

})
export class AllMessagesComponent implements OnInit {

  busy: Subscription;
  conversations: Array<Conversation>;
  baseUrl = environment.baseUrl + '/';

  constructor(private conversationServices: ConversationService) {

  }

  ngOnInit() {
    this.getAllMessages();
  }

  private getAllMessages() {
    this.busy = this.conversationServices.getStudentAllConversations().subscribe(data => {
      this.conversations = data;
    })
  }
}
