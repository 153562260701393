import {HttpClient} from '@angular/common/http';
import {GenericService} from './generic.service';
import {Injectable} from '@angular/core';
import {Credentials} from "../models/credentials";
import {catchError} from 'rxjs/operators';
import {StorageService} from "./storage.service";
import {Student} from "app/shared/models/student";
import {environment} from "../../../environments/environment";


@Injectable()
export class AuthService extends GenericService {

  constructor(private http: HttpClient, private stoarageService: StorageService) {
    super();
  }


  login(credentials: Credentials) {
    const url = environment.baseUrl + '/auth/login';
    console.log("login", JSON.stringify(credentials));
    return this.http.post<any>(url, {
      "email": credentials.login,
      "password": credentials.password
    }).pipe(
      catchError(this.handleErrors)
    )
  }


  registration(student: Student) {

  }

  isLoggedIn() {
    return this.stoarageService.read("token") != null;
  }

}
