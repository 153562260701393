import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {HttpClient} from "@angular/common/http";
import {catchError} from 'rxjs/operators';
import {StorageService} from "./storage.service";
import {UserService} from "./user.service";
import {Formation} from "../models/formation";
import {environment} from "../../../environments/environment";

@Injectable()
export class PostGraduateTrainingService extends GenericService {

  constructor(private http: HttpClient, private storageService: StorageService,
              private userService: UserService) {
    super();
  }

  getAllFormationByStudent(sessionFormationId: number) {
    const url = environment.baseUrl + '/student/session-formation/' + sessionFormationId + '/formation/all';
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.get(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  addCandidateChoice(formations: Formation[]) {
    const url = environment.baseUrl + '/student/formation/choice';
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.post(url, formations, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  getChoicesByCandidat(sessionFormationId) {
    const url = environment.baseUrl + '/student/session-formation/' + sessionFormationId + '/formation/choice';
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.get(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  getSessionFormationByYearUniversity(year_university: string) {
    const url = environment.baseUrl + '/student/session-formation/year-university';
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.post(url, {
        year_university: year_university
      }, {
        headers: headers
      }
    ).pipe(catchError

      (
        this
          .handleErrors
      )
    )
  }

  getAllInscriptionsByStudent(sessionFormationId: number) {
    const url = environment.baseUrl + '/student/session-formation/' + sessionFormationId + '/inscriptions';
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.get(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }


  getPreInscriptionByStudent() {
    const url = environment.baseUrl + '/student/formation/preinscriptions';
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.get(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }
}
