import {NgModule} from '@angular/core';
import {StageComponent} from './stage/stage.component';
import {EnseignementModuleRouting} from "./enseignement.routing";
import {SharedModule} from "../shared/shared.module";
import {DetailsEvaluationComponent} from './details-evaluation/details-evaluation.component';

@NgModule({
  imports: [
    EnseignementModuleRouting,
    SharedModule
  ],
  declarations: [StageComponent, DetailsEvaluationComponent
  ]
})
export class EnseignementModule {
}
