import {Component, OnInit} from '@angular/core';
import {EcosService} from "../../shared/services/ecos.service";
import {Subscription} from "rxjs";
import {NoteExamen} from "../../shared/models/ecos/NoteExamen";
import {Session} from "../../shared/models/ecos/session";
import {EtudiantExamen} from "../../shared/models/ecos/EtudiantExamen";

@Component({
  selector: 'app-result-ecos',
  templateUrl: './result-ecos.component.html',
  styleUrls: ['./result-ecos.component.css']
})
export class ResultEcosComponent implements OnInit {
  busy: Subscription;
  notes_examens: EtudiantExamen[] = [];

  constructor(private ecosService: EcosService) {
  }

  ngOnInit() {
    this.getResults();
  }

  getResults() {
    this.busy = this.ecosService.getResultats().subscribe((data) => {
      this.notes_examens = data.reverse();
      if (this.notes_examens.length > 0) {
        const etudiantExaman = this.notes_examens[0];
        if (etudiantExaman.decision_jury === 2 || etudiantExaman.decision_jury === 3) {
          this.getNextSessions(etudiantExaman);
        }
      }
    });
  }

  // @ts-ignore
  private getNextSessions(etudiantExaman: EtudiantExamen) {
    this.busy = this.ecosService.getSessionsByNiveauIdAndAfterToday(etudiantExaman.examen.session.id_Niveau).subscribe((data) => {
        etudiantExaman.nextSessions = data;
      }
    );
  }
}
