import {SectionValidation} from "./models/section-validation";
import {PartielNotificationDetection} from "./models/PartielNotificationDetection";
import {Formation} from "./models/formation";
import {ItemSessionFormation} from "./models/item-session-formation";
import {Study} from "./models/study";
import {st} from "@angular/core/src/render3";
import {SeanceSeminar} from "./models/mseminar/SeanceSeminar";

/**
 * Created by Abbes on 30/06/2017.
 */
declare var jQuery: any;

export class Utils {


  public static zero(n: number) {
    if (n < 10) {
      return "0" + n;
    }
    return n;
  }

  static getLabelNiveau(niveau_id: number) {
    switch (niveau_id) {
      case 1 :
        return "PCEM1";
      case 2 :
        return "PCEM2";
      case 3 :
        return "DCEM1";
      case 4 :
        return "DCEM2";
      case 5 :
        return "DCEM3";
      case 6 :
        return "DCEM4";
      case 7 :
        return "DCEM4 (AR)";
      case 8:
        return "TCEM1";
      case 9:
        return "TCEM2";
      case 10:
        return "TCEM3";
      case 11:
        return "TCEM4";
      case 12:
        return "TCEM5";

    }
  }

  static configDataTables() {
    /* jQuery.extend(jQuery.fn.dataTable.defaults, {
     autoWidth: false,
     columnDefs: [{
     orderable: false,
     width: '100px',
     targets: [5]
     }],
     dom: '<"datatable-header"fl><"datatable-scroll"t><"datatable-footer"ip>',
     language: {
     search: '<span>Filter:</span> _INPUT_',
     lengthMenu: '<span>Show:</span> _MENU_',
     paginate: {'first': 'First', 'last': 'Last', 'next': '&rarr;', 'previous': '&larr;'}
     },
     drawCallback: function () {
     jQuery(this).find('tbody tr').slice(-3).find('.dropdown, .btn-group').addClass('dropup');
     },
     preDrawCallback: function () {
     jQuery(this).find('tbody tr').slice(-3).find('.dropdown, .btn-group').removeClass('dropup');
     }
     });*/
  }


  static getStatusSection(validations: SectionValidation[], id_section: number) {

    if (!validations) {
      return null;
    }
    const result = jQuery.grep(validations, function (e) {
      return e.id_section === id_section;
    });


    if (!result || result.length === 0) {
      return null;
    } else {
      return result[0];
    }
  }

  private static groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };


  static initializeDataTables(timout: number, columnNumber: number) {
    // Basic datatable
    const tableListStation = jQuery('.datatable-basic');
    setTimeout(function () {
      tableListStation.DataTable({
        columnDefs: [{
          targets: [columnNumber - 1]
        }]
      });
    }, timout);
  }

  static convertDate(date: string) {
    if (date) {
      return date.substring(8, 10) + "/" + date.substring(5, 7) + "/" + date.substring(0, 4);
    }
    return null;
  }

  static convertTime(time: string) {
    if (time) {
      return time.substring(0, 5);
    }
    return null;
  }

  static convertRealDate(date: string, time?: string) {
    if (date) {
      console.log(date);

      console.log(date.substring(0, 2), date.substring(3, 5), date.substring(6, 10));
      if (!time) {
        return new Date(+date.substring(6, 10), (+date.substring(3, 5) - 1), +date.substring(0, 2));
      } else {
        return new Date(+date.substring(6, 10), (+date.substring(3, 5) - 1),
          +date.substring(0, 2), +time.substring(0, 2), +time.substring(3, 5));
      }
    }
    return null;
  }

  static getModalTemplate() {
    return '<div class="modal-dialog modal-lg" role="document">\n' +
      '  <div class="modal-content">\n' +
      '    <div class="modal-header">\n' +
      '      <div class="kv-zoom-actions btn-group">{toggleheader}{fullscreen}{borderless}{close}</div>\n' +
      '      <h6 class="modal-title">{heading} <small><span class="kv-zoom-title"></span></small></h6>\n' +
      '    </div>\n' +
      '    <div class="modal-body">\n' +
      '      <div class="floating-buttons btn-group"></div>\n' +
      '      <div class="kv-zoom-body file-zoom-content"></div>\n' + '{prev} {next}\n' +
      '    </div>\n' +
      '  </div>\n' +
      '</div>\n';
  }

  static getPreviewZoomButtonClasses() {
    return {
      toggleheader: 'btn btn-default btn-icon btn-xs btn-header-toggle',
      fullscreen: 'btn btn-default btn-icon btn-xs',
      borderless: 'btn btn-default btn-icon btn-xs',
      close: 'btn btn-default btn-icon btn-xs'
    };
  }

  static getPreviewZoomButtonIcons() {
    return {
      prev: '<i class="icon-arrow-left32"></i>',
      next: '<i class="icon-arrow-right32"></i>',
      toggleheader: '<i class="icon-menu-open"></i>',
      fullscreen: '<i class="icon-screen-full"></i>',
      borderless: '<i class="icon-alignment-unalign"></i>',
      close: '<i class="icon-cross3"></i>'
    };
  }

  static initializeBasicUploadFile(url: string, token: string, className: string) {
    jQuery('.' + className).fileinput({
      uploadUrl: url, // server upload action
      browseLabel: 'Browse',
      browseIcon: '<i class="icon-file-plus"></i>',
      uploadIcon: '<i class="icon-file-upload2"></i>',
      removeIcon: '<i class="icon-cross3"></i>',
      initialCaption: "No file selected",
      ajaxSettings: {headers: {'Authorization': 'Bearer ' + token}},
    });
  }

  static initializeUploadFile(url: string, token: string, className: string, maxFileCount?: number, initialData?: any[],
                              initialPreviewConfig?: InitialPreviewConfig[], allowedFileExtensions?: string[]) {
    jQuery(className).fileinput({
      uploadUrl: url, // server upload action
      uploadAsync: true,
      allowedFileExtensions: allowedFileExtensions,
      maxFileCount: maxFileCount,
      showUpload: false,
      showRemove: false,
      initialPreview: initialData,
      overwriteInitial: false,
      initialPreviewAsData: true,
      initialPreviewFileType: 'image', // image is the default and can be overridden in config below
      initialPreviewConfig: initialPreviewConfig,
      dropZoneTitle: "Pas encore de fichier(s) selectionné(s)",
      fileActionSettings: {
        removeIcon: '<i class="icon-bin"></i>',
        removeClass: 'btn btn-link btn-xs btn-icon',
        uploadIcon: '<i class="icon-upload"></i>',
        uploadClass: 'btn btn-link btn-xs btn-icon',
        zoomIcon: '<i class="icon-zoomin3"></i>',
        zoomClass: 'btn btn-link btn-xs btn-icon',
        downloadIcon: '<i class="icon-download"></i>',
        downloadClass: 'btn btn-link btn-xs btn-icon',
        indicatorNew: '<i class="icon-file-plus text-slate"></i>',
        indicatorSuccess: '<i class="icon-checkmark3 file-icon-large text-success"></i>',
        indicatorError: '<i class="icon-cross2 text-danger"></i>',
        indicatorLoading: '<i class="icon-spinner2 spinner text-muted"></i>',
      },
      purifyHtml: true, // this by default purifies HTML data for preview
      initialCaption: "Pas encore de fichier selectionné",
      previewZoomButtonClasses: Utils.getPreviewZoomButtonClasses(),
      previewZoomButtonIcons: Utils.getPreviewZoomButtonIcons(),
      ajaxSettings: {headers: {'Authorization': 'Bearer ' + token}},
    }).on("filebatchselected", function (event, files) {
      jQuery(className).fileinput("upload");
    });
  }

  static convertDateServer(date: string) {
    return date.substring(6, 10) + "-" + date.substring(3, 5) + "-" + date.substring(0, 2);
  }

  static loadTypeFromExtension(ext: string) {
    if (ext.toLowerCase().match(/(avi|mpg|mkv|mov|mp4|3gp|webm|wmv)$/i)) {
      return "video";
    }
    if (ext.toLowerCase().match(/(pdf)$/i)) {
      return "pdf";
    }
  }

  static loadFileTypeFromExtension(ext: string) {
    if (ext.toLowerCase().match(/(avi|mpg|mkv|mov|mp4|3gp|webm|wmv)$/i)) {
      return "video/" + ext;
    }
    if (ext.toLowerCase().match(/(pdf)$/i)) {
      return "pdf";
    }
  }

  static getYears(minYear: number) {
    const years: number[] = [];

    for (let i = minYear; i <= new Date().getFullYear(); i++) {
      years.push(i);
    }
    return years;
  }

  static getUniversityYears(minYear: number) {
    const uniYears: string[] = [];

    for (let i = minYear; i <= new Date().getFullYear(); i++) {
      const j = i + 1;
      uniYears.push(i + "-" + j);
    }

    return uniYears;
  }

  static verifyNewStudent(study_access_year: string) {
    if (!study_access_year) {
      return false;
    }
    const year = parseInt(study_access_year.substring(0, 4));
    return year === new Date().getFullYear();
  }

  static convertRealDateServer(date: string) {
    if (date) {
      const HH = +date.substring(11, 13);
      const mm = +date.substring(14, 16);

      // console.log(new Date(+date.substring(0, 4), (+date.substring(5, 7) - 1), +date.substring(8, 10), HH, mm));

      return new Date(+date.substring(0, 4), (+date.substring(5, 7) - 1), +date.substring(8, 10), HH, mm);
    }
    return null;
  }

  static getPartielNotification(partielId: number, data: PartielNotificationDetection[]) {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].sub_section.section.id_Partiel_Notification === partielId) {
        sum++;
      }
    }
    return sum;
  }

  static getNotificationBySection(sectionId: number, data: PartielNotificationDetection[]) {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].sub_section.id_Partiel_Notification_Section === sectionId) {
        sum++;
      }
    }
    return sum;
  }

  static getNotificationBySubSection(subSectionId: number, data: PartielNotificationDetection[]) {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id_Partiel_Notification_Sub_Section === subSectionId) {
        sum++;
      }
    }
    return sum;
  }

  static dateBetween(startDate: Date, endDate: Date) {
    console.log(startDate);
    console.log(endDate);
    console.log(new Date());
    return new Date() >= startDate && new Date() <= endDate;
  }

  static getCurrentUniversityYear(cycle: number, pas: number = 0, yearPas: number = 0) {
    // cycle => 1 : PDCEM , 2 : IRESIDANT
    let fullYear = new Date().getFullYear();
    if (cycle === 1) {
      if (new Date().getMonth() >= 0 && new Date().getMonth() <= 6 - pas) {
        fullYear -= 1;
      }
    } else {
      if (new Date().getMonth() >= 0 && new Date().getMonth() <= 7) {
        fullYear -= 1;
      }
    }
    return (fullYear - yearPas) + "-" + (fullYear + 1 - yearPas)
  }

  static getCycleByLevel(id_level: number) {
    if (id_level >= 1 && id_level <= 5) {
      return 1;
    } else {
      return 2;
    }

  }

  static getPosSortable(parent: string, child: string) {
    return jQuery(parent + ' ' + child).map(function (i) {
      return parseInt(this.id, 0);
    }).get();
  }

  static convertToFormation(itemsSession: ItemSessionFormation[]) {

    let formations: Formation[] = [];

    itemsSession.forEach(
      item => {
        let formation = item.formation;
        formation['capacite'] = item.nb_places;
        formation['id_item_session_formation'] = item.id_item_session_formation;
        formations.push(formation);
      }
    );
    return formations;
  }


  static getRandomString() {
    var text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (var i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  static initSelect2(className: string, isSearch?: boolean) {
    const select = jQuery('.' + className);
    if (isSearch === false) {
      select.select2({
        minimumResultsForSearch: Infinity
      });
    } else {
      select.select2();
    }
  }

  static setValuesjQueryCmp(className: string, values: any, timout: number) {
    if (values) {
      setTimeout(function () {
        const component = jQuery('.' + className);
        component.val(values).trigger('change');
      }, timout);

    }
  }

  static getRangPerYear(studies: Study[], currentUniversityYear: string) {

    for (let i = 0; i < studies.length; i++) {
      if (studies[i].year === currentUniversityYear) {
        return studies[i].classement;
      }
    }
    return null;

    // return 0;
  }

  static initSortableElements(className: any, timout: number = 0) {
    setTimeout(function () {
      jQuery("." + className).sortable();
      jQuery("." + className).disableSelection();
    }, timout);

  }

  static getDiffDates(startDate: Date, endDate: Date) {
    const difference = endDate.getTime() - startDate.getTime();
    console.log(Math.round(difference / 60000));
    return Math.round(difference / 60000);
  }

  static filterObjectsDistinct(array: any[], key: string, prop: string) {
    const allObjects = array.map(item => {
      return item[key];
    });
    return allObjects.filter((obj, pos, arr) => {
      return arr.map(mapObj =>
        mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  public static groupByM(array, f) {
    let groups = {};
    array.forEach(function (o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    })
  }
}


export class InitialPreviewConfig {
  caption?: string;
  size?: number;
  width?: string;
  type?: string;
  filetype?: string;
  url: string;
  key: number;
  downloadUrl?: string;
}

