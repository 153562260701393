import {Level} from "../level";
import {SessionOU} from "./session-ou";
import {StudentChoixOu} from "./student-choix-ou";
import {GroupeOU} from "./groupe-ou";

export class SessionChoixOU {
  id_session_choix_ou: number;

  title: string;
  start_date: string;
  end_date: string;
  year_university: string;

  level: Level = new Level();

  id_level: number;

  groupes_ou: GroupeOU[] = [];
  sessions_ou: SessionOU[] = [];
}
