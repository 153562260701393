import {Component, OnInit} from '@angular/core';
import {Evaluation} from "../../shared/models/stage/Evaluation";
import {StageService} from "../../shared/services/stage.service";
import {Subscription} from "rxjs/Subscription";
import {Utils} from "../../shared/utils";
import * as FileSaver from "file-saver";
import {Router} from "@angular/router";

@Component({
  selector: 'app-stage',
  templateUrl: './stage.component.html',
  styleUrls: ['./stage.component.css']
})
export class StageComponent implements OnInit {


  busy: Subscription;
  stages: Evaluation[] = [];

  constructor(private stageService: StageService, private router: Router) {
  }

  ngOnInit() {

    this.getStagesByStudent();
  }

  getStagesByStudent() {
    this.busy = this.stageService.getStagesByStudent()
      .subscribe(
        (data: Evaluation[]) => {
          this.stages = data;
          Utils.initializeDataTables(5, 4);
        }
      )
  }

  generateAttestation() {
    this.busy = this.stageService.generateAttestationStage()
      .subscribe(
        (data: any) => {
          FileSaver.saveAs(data, "Attestation de validation de stage.pdf");
        },
        (error) => {
          console.log(error);
        }
      )
  }

  goToEvaluation(i: number) {
    this.stageService.evaluation = this.stages[i];
    this.router.navigate(["/enseignement/details-evaluation"]);
  }
}
