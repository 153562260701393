import {Component, OnInit} from '@angular/core';
import {PreInscriptionFormation} from "../../shared/models/pre_inscription_formation";
import {PostGraduateTrainingService} from "../../shared/services/post-graduate-training.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-result-masteres',
  templateUrl: './result-masteres.component.html',
  styleUrls: ['./result-masteres.component.css']
})
export class ResultMasteresComponent implements OnInit {

  presinscriptions: PreInscriptionFormation[] = [];

  busy: Subscription;

  constructor(private postGraduateTrainingService: PostGraduateTrainingService) {
  }

  ngOnInit() {

    this.busy = this.postGraduateTrainingService.getPreInscriptionByStudent()
      .subscribe(
        (data: PreInscriptionFormation[]) => {
          this.presinscriptions = data;
        },
        (error) => {

        }
      )
  }

}
