import {Component, OnInit} from "@angular/core";
import {Subscription} from "rxjs/Subscription";
import {Session_Stage} from "../../../shared/models/stage/Session_Stage";
import {StageService} from "../../../shared/services/stage.service";
import {Utils} from "../../../shared/utils";

declare var swal: any;
declare var jQuery;

@Component({
  templateUrl: 'session-stage.component.html',
  styleUrls: [],
})
export class SessionStageComponent implements OnInit {
  busy: Subscription;
  sessionStage: Session_Stage = new Session_Stage();


  ngOnInit() {
    console.log("Session Stage");

    this.busy = this.stageService.getSessionsStage()
      .subscribe(
        (data) => {
          this.sessionStage = data;
          /*this.sessionsStage.forEach(
            sessionStage => {

              if (Utils.dateBetween(Utils.convertRealDateServer(sessionStage.date_choice_open),
                Utils.convertRealDateServer(sessionStage.date_choice_close))) {
                sessionStage.statusChoice = 1;
              } else if (Utils.dateBetween(Utils.convertRealDateServer(sessionStage.date_service_open),
                Utils.convertRealDateServer(sessionStage.date_service_close))) {
                sessionStage.statusChoice = 2;
              } else {
                sessionStage.statusChoice = 0;
              }

              console.log(sessionStage.statusChoice);
            })*/
        }
      )
  }

  constructor(private stageService: StageService) {
  }
}
