import {Component, OnInit} from '@angular/core';
import {OptionalUnitService} from "../../shared/services/optional-unit.service";
import {Subscription} from "rxjs/Rx";
import {SessionChoixOU} from "../../shared/models/optional-unit/session-choix-ou";
import {SessionOU} from "../../shared/models/optional-unit/session-ou";
import {UserService} from "../../shared/services/user.service";
import {Student} from "../../shared/models/student";
import {Utils} from "../../shared/utils";
import {OptionalUnit} from "../../shared/models/optional-unit/optional-unit";
import {StudentClassOu} from "../../shared/models/optional-unit/student-class-ou";


declare var jQuery: any;
declare var swal: any;

@Component({
  selector: 'app-optional-unit-choice',
  templateUrl: './optional-unit-choice.component.html',
  styleUrls: ['./optional-unit-choice.component.css']
})
export class OptionalUnitChoiceComponent implements OnInit {


  sessionChoice: SessionChoixOU = new SessionChoixOU();
  busy: Subscription;

  selectedSessionOu: SessionOU = new SessionOU();

  maxNumberChoice: number;
  loggedUser: Student;

  seancesGrouped: SeanceUO[] = [];

  groupeclassNames: {
    class: string,
    groupe_id: number,
    orders?: string[]
  }[] = [];


  constructor(private optionalUnitService: OptionalUnitService, private userService: UserService) {
    this.loggedUser = this.userService.loggedUser;
  }

  ngOnInit() {
    this.loadSessionActive();
  }


  openDetails(index: number) {
    this.selectedSessionOu = this.sessionChoice.sessions_ou[index];
    jQuery('.modal-optional-unit-choice').modal();
  }

  private loadSessionActive() {


    this.busy = this.optionalUnitService.getSessionActiveByStudent()
      .subscribe(
        (data: SessionChoixOU) => {
          this.sessionChoice = data;

          this.optionalUnitService.getStudentsChoices(this.sessionChoice.id_session_choix_ou)
            .subscribe(
              (choices: StudentClassOu[]) => {
                this.transformData(choices);
              },
              (error) => {

              });
        },
        (error) => {
        }
      )
  }

  private transformData(choices: StudentClassOu[]) {

    if (choices.length === 0) {
      let optionalUnits: OptionalUnit[] = [];
      optionalUnits = this.sessionChoice.sessions_ou.map(
        item => {
          return item.optional_unit;
        }
      );

      this.sessionChoice.groupes_ou.forEach(
        groupe => {
          groupe.optionalUnits = [];
          groupe.domaines.forEach(domaine => {
            let units = optionalUnits.filter(unit => {
              return unit.id_domaine === domaine.id_domaine;
            });
            groupe.optionalUnits.push(...units);
          });

          Utils.initSortableElements('sortable_groupe_' + groupe.id_group_ou, 10);
          this.groupeclassNames.push({
            class: "sortable_groupe_" + groupe.id_group_ou,
            groupe_id: groupe.id_group_ou
          });
        }
      );
    } else {
      this.sessionChoice.groupes_ou.forEach(
        groupe => {
          let units: OptionalUnit[] = choices.filter(item => {
            return item.id_group_ou === groupe.id_group_ou;
          }).sort((a, b) => {
            return a.choice === b.choice ? 0 : +(a.choice > b.choice) || -1;
          }).map(item => {
            return item.optional_unit;
          })
          groupe.optionalUnits = units;

          Utils.initSortableElements('sortable_groupe_' + groupe.id_group_ou, 10);
          this.groupeclassNames.push({
            class: "sortable_groupe_" + groupe.id_group_ou,
            groupe_id: groupe.id_group_ou
          });
        }
      )
    }
  }

  inscriptionUO() {

    if (Utils.getDiffDates(Utils.convertRealDateServer(this.sessionChoice.start_date), new Date()) < 0) {
      swal('Erreur', "La session n'est pas ouverte à l'instant contactez l'administration", 'error');
      return;
    }


    for (let i = 0; i < this.groupeclassNames.length; i++) {
      this.groupeclassNames[i].orders = jQuery('.' + this.groupeclassNames[i].class).sortable("toArray");
    }

    this.busy = this.optionalUnitService
      .inscriptionUO(this.sessionChoice.id_session_choix_ou, this.groupeclassNames)
      .subscribe(
        (data) => {
          swal('Succées', 'Vous êtes inscrit avec succées', 'success');
        },
        (error) => {

        }
      )


    /*this.busy = this.optionalUnitService
      .inscriptionUO(this.sessionChoice.id_session_choix_ou, this.sessionChoice.sessions_ou[indexSessionUO].id_optional_unit
        , (choiceNumber + 1))
      .subscribe((data: SessionChoixOU) => {
          this.sessionChoice = data;
          this.transformData();
          swal('Succées', 'Vous êtes inscrit avec succées', 'success');
        },
        (error) => {
          console.log(error);
          if (error.status === 403) {
            swal('Erreur', "La session n'est pas ouverte à l'instant contactez l'administration", 'error');
          }

          if (error.status === 405) {
            swal('Erreur', "Cette unité à atteint sa capacité verifier une autre unité", "error");
          }

          if (error.status === 404) {
            swal('Erreur', "Vous n'êtes pas inscrit dans l'année universitaire, contactez l'administration", "error");
          }
          if (error.status === 406) {
            swal('Erreur', "Il faut attendre un peu pour votre prochain essai", "error");
          }
        });*/
  }

}

export class SeanceUO {
  public id: number;
  public optionalUnits: SessionOU[] = [];
}
