import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {SharedModule} from "../shared/shared.module";
import {ManageStudentFileRouting} from "./student-file.routing";
import {DashboardStudentFileComponent} from "./dashboard/dashboard-student-file.component";
import {BacInfoComponent} from "./bac-info/bac-info.component";
import {StudiesStudentFileComponent} from "./studies/studies-student-file.component";
import {PhotoComponent} from "./photo/photo.component";
import {FonctionStudentFileComponent} from "./fonction/fonction-student-file.component";
import {DoctauratStudentFileComponent} from "./doctaurat/doctaurat-student-file.component";
import {ResidanatStudentFileComponent} from "./residanat/residanat-student-file.component";
import {DescriptifDocumentComponent} from "app/student-file/shared/descriptif-document/descriptif-document.component";

@NgModule({
  imports: [
    ManageStudentFileRouting,
    SharedModule
  ],
  declarations: [
    DashboardStudentFileComponent,
    BacInfoComponent,
    StudiesStudentFileComponent,
    PhotoComponent,
    FonctionStudentFileComponent,
    DoctauratStudentFileComponent,
    ResidanatStudentFileComponent,
    DescriptifDocumentComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: []
})
export class ManageStudentFileModule {
}
