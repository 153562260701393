import {Component, OnInit} from '@angular/core';
import {StudentChoixOu} from "../../shared/models/optional-unit/student-choix-ou";
import {OptionalUnitService} from "../../shared/services/optional-unit.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-result-uo',
  templateUrl: './result-uo.component.html',
  styleUrls: ['./result-uo.component.css']
})
export class ResultUoComponent implements OnInit {

  studentChoices: StudentChoixOu[] = [];
  busy: Subscription;

  constructor(private optionalUnitService: OptionalUnitService) {
  }

  ngOnInit() {

    this.busy = this.optionalUnitService.getChoicesStudent()
      .subscribe(
        (data: StudentChoixOu[]) => {
          this.studentChoices = data;
        },
        (error) => {

        }
      )
  }

}
