import {Teacher} from "./Teacher";
import {Student} from "./student";
import {Formation} from "./formation";
import {ItemSessionFormation} from "./item-session-formation";

export class Inscription_Formation {
  id_inscription_formation: number;
  id_teacher: number;
  id_student: number;
  id_formation: number;
  status: number;

  teacher: Teacher;
  student: Student;
  formation: ItemSessionFormation;
  paiement: string;

}
