import {City} from "./city";
import {Niveau} from "./niveau";
/**
 * Created by Abbes on 04/08/2017.
 */

export class Session {
  public id_Session: number;
  public nom: string;
  public id_Niveau: number;
  public date_debut: string;
  public date_fin: string;
  public date_publication: string;
  public time_publication: string;
  public date_fin_inscription: string;
  public time_fin_inscription: string;
  public niveau: Niveau;
  public isInscriptionPossible: number;
}
