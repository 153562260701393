import {CriterePortfolio} from "./CriterePortfolio";

export class EvaluationPortfolioItem {
  evaluation_portfolio_item_id: number;
  validation: number;
  document_number: number;
  critere_portfolio_id: number;
  evaluation_portfolio_id: number;

  competance: CriterePortfolio;
}
