import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {StorageService} from "../shared/services/storage.service";
import {Student} from "../shared/models/student";
import {UserService} from "../shared/services/user.service";
import * as FileSaver from "file-saver";
import {EcosService} from "../shared/services/ecos.service";
import {ConversationService} from "../shared/services/conversation.service";
import {PartielNotificationService} from "../shared/services/partielNotification.service";
import {Utils} from "../shared/utils";
import {StudentFileService} from "../shared/services/student-file.service";
import {Subscription} from "rxjs/Subscription";
import {NotificationService} from "../shared/services/notification.service";
import {environment} from "../../environments/environment";

declare let jQuery: any;
declare let swal: any;

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html'
})
export class FullLayoutComponent implements OnInit {

  readonly PRINT_STUDENT_FILE_PDF = 1;
  readonly PRINT_STUDENT_QRCODE_PDF = 2;
  readonly PRINT_STUDENT_ATTESTATION_INSCRIPTIon_PDF = 3;
  navCollapsed = false;
  components: NavigationMain[] = [];
  student: Student;
  conversationCount = 0;
  isRegistredUniversity: any;
  busy: Subscription;

  constructor(private storageService: StorageService,
              public ecosService: EcosService,
              public router: Router, public userService: UserService,
              private route: ActivatedRoute,
              private studentFileService: StudentFileService,
              private partielNotificationService: PartielNotificationService,
              private conversationService: ConversationService,
              private notificationService: NotificationService) {
    this.student = userService.loggedUser;
    if (!this.student) {
      userService.loggedUser = this.student = new Student();
    }
    this.userService.getLoggedUser().subscribe(data => {
      this.student = this.userService.loggedUser = data.user;
      this.student.isNew = this.userService.loggedUser.isNew = Utils.verifyNewStudent(this.student.study_access_year);
      this.storageService.write("student", this.student);
      // this.router.navigate(["/student-file"]);
    }, error => {
      this.storageService.removeAll();
      this.router.navigateByUrl('login');
    });

    /*this.conversationService.getConversationsCount().subscribe(data => {
      this.components[6].notification = this.components[6].childrens[0].notification = this.conversationCount = data.count;
    });*/
    this.checkIfUserPassedTestPsychometrique();
  }

  getStudent(): Student {
    return this.userService.loggedUser ? this.userService.loggedUser : new Student();
  }

  ngOnInit() {

    this.userService.checkIfUserIsRegistredUniversity()
      .subscribe(
        (data) => {
          console.log(data);
          this.isRegistredUniversity = data.registration;
          this.components[3].childrens[2].hidden = this.isRegistredUniversity === null;
        },
        (error) => {
          console.log(error);
        }
      );

    /*this.partielNotificationService.getNewNotifications()
      .subscribe(
        (data) => {
          console.log(data);
          this.components[2].notification += Utils.getPartielNotification(3, data);
          this.components[2].childrens[1].notification += Utils.getNotificationBySection(2, data);
        },
        (error) => {
          console.log(error);
        }
      );*/
    this.components = [
      {
        name: "Mon profil",
        url: "/student-file",
        icon: "icon-profile",
        visible: true,
      },
      /*{
        name: "Test Psychométrique",
        url: "/test-psychometrique",
        icon: "icon-copy",
        visible: true,
      },*/
      {
        name: "Enseignement",
        visible: this.userService.loggedUser.administration_review === 1,
        icon: "icon-graduation2",
        childrens: [
          {
            name: "Mes cours",
            url: "/error/construction"
          },
          {
            name: "Mes travaux pratiques",
            url: "/error/construction"
          },
          {
            name: "Mes travaux dirigés",
            url: "/error/construction"
          },
          {
            name: "Mes stages",
            url: "/enseignement/stage"
          },
          {
            name: "Mes séminaires",
            url: "/error/construction"
          },
          {
            name: "Mes options",
            url: "/error/construction"
          },
          {
            name: "Mes résultats",
            url: "/error/construction"
          }]
      },
      {
        name: "Administratif",
        visible: this.userService.loggedUser.administration_review === 1,
        icon: "icon-office",
        notification: 0,
        childrens: [
          {
            name: "Mes emprunts",
            url: "/error/construction"
          },
          {
            name: "Mes inscriptions",
            url: "/inscription/university-year",
            notification: 0
          },
          {
            name: "Mes demandes",
            url: "/error/construction"
          }]
      },
      {
        name: "Impressions",
        visible: this.userService.loggedUser.administration_review === 1,
        icon: "icon-printer",
        childrens: [
          {
            name: "Mon Dossier PDF",
            action: this.PRINT_STUDENT_FILE_PDF
          },
          {
            name: "Mon identifiant QrCode",
            action: this.PRINT_STUDENT_QRCODE_PDF
          },
          {
            name: "Mon attestation d'inscription",
            action: this.PRINT_STUDENT_ATTESTATION_INSCRIPTIon_PDF,
          }]
      },
      {
        name: "Mon portfolio",
        url: "/portfolio/style-cognitif",
        icon: "icon-user",
        visible: this.userService.loggedUser.administration_review === 1,
      },
      {
        name: "J’évalue",
        url: "/error/construction",
        icon: "icon-thumbs-up3",
        visible: this.userService.loggedUser.administration_review === 1,
      },
      {
        name: "Assistance",
        icon: "icon-lifebuoy",
        visible: true,
        notification: this.conversationCount,
        childrens: [
          {
            name: "Mes réclamations",
            url: "/support/messages/all",
            notification: this.conversationCount
          },
          {
            name: "Nouvelle réclamation",
            url: "/support/reclamation"
          }]
      },


      /*{
       name: "Assistance",
       icon: "icon-pencil3",
       childrens: [
       {
       name: "Liste",
       url: "/session/list"
       },
       {
       name: "Ajout",
       url: "/session/add"
       }]
       },
       {
       name: "Gestion de la banque",
       icon: "icon-database2",
       childrens: [
       {
       name: "Liste des Stations",
       url: "/banque/list"
       },
       {
       name: "Ajout d'une stations",
       url: "/banque/add"
       },
       {
       name: "Evaluation",
       url: "/banque/evaluer"
       }
       ]
       },
       {
       name: "Gestion des Privileges",
       url: "/privilege/affect",
       icon: "icon-user-lock"
       },
       {
       name: "Organisation",
       icon: "icon-wave2",
       childrens: [
       {
       name: "Liste",
       url: "/organisation/list-personelle"
       },
       {
       name: "Suivi et controle",
       url: "/organisation/station"
       }
       ]
       },
       {
       name: "Gestion des Notes",
       url: "/notes",
       icon: "icon-file-check2"
       },*/
    ];
    this.route.queryParams.subscribe(
      params => {
        console.log(params.reload);
        if (params.reload) {
          window.location.href = "/";
        }
      }
    );

    this.changeActiveUrl(this.router.url);

    if (!this.storageService.read("student-token") && !this.storageService.read("enseignant-token")) {
      this.router.navigate(["/login"]);
    }

    this.getNumberOfSessions();
  }

  changeActiveUrl(url: string) {
    console.log(url);
    this.components.forEach(
      component => {
        component.active = "";
        if (url.indexOf(component.url) !== -1) {
          component.active = "active";
        }
        if (component.childrens) {
          component.childrens.forEach(
            child => {
              child.active = "";
              if (url.indexOf(child.url) !== -1) {
                child.active = "active";
              }
            }
          )
        }
      }
    )
  }

  logout() {
    this.storageService.removeAll();
    this.router.navigateByUrl("/login");
  }

  goUrl(url: string) {
    console.log("url + ", url);
    if (url) {
      this.router.navigate([url]);
    }
    this.checkIfUserPassedTestPsychometrique()
  }

  triggerChildAction(child: ChildrenNavigation) {
    this.goUrl(child.url);
    if (child.action) {
      switch (child.action) {
        case this.PRINT_STUDENT_FILE_PDF:
          this.generateDetailPDFStudent();
          break;
        case this.PRINT_STUDENT_QRCODE_PDF:
          this.generateQrcodeStudent();
          break;
        case this.PRINT_STUDENT_ATTESTATION_INSCRIPTIon_PDF:
          this.generateAttestationInscriptionPDF();
          break;
      }
    }
  }

  generateQrcodeStudent() {
    this.userService.generateQRcodeStudent()
      .subscribe(
        (data) => {
          FileSaver.saveAs(data, this.student.first_name + " " + this.student.last_name + "QRcode.pdf");
        },
        (error) => {

        }
      )
  }

  generateDetailPDFStudent() {

    this.busy = this.userService.generateDetailPDFStudent()
      .subscribe(
        (data) => {
          FileSaver.saveAs(data, this.student.first_name + " " + this.student.last_name + "_Dossier.pdf");
        },
        (error) => {
          if (error.status === 400) {
            swal('Warning', 'Vous n\'êtes pas inscrits pour l\'année universitaire en cours', 'warning');
          }
        }
      )
  }

  generateAttestationInscriptionPDF() {
    this.busy = this.studentFileService.generationAttestationFr()
      .subscribe(
        (data) => {
          FileSaver.saveAs(data, "Attestation d'inscription.pdf");
        },
        (error) => {

        }
      )
  }

  getStudentImgUrl() {
    return this.getStudent().img ? environment.baseUrl + '/' + this.getStudent().img.path : 'assets/images/placeholder.jpg';
  }

  getNumberOfSessions() {
    const baseContext = this;
    baseContext.ecosService.getSessionsActives().subscribe(data => {
      baseContext.userService.nbr_sessions = data.length;
      this.components[2].notification += data.length;
      this.components[2].childrens[1].notification += data.length;
    });
  }

  switchNavCollapseMode() {
    this.navCollapsed = !this.navCollapsed
  }

  private checkIfUserPassedTestPsychometrique() {
    this.userService.getUserTestPsychometrique().subscribe(data => {
    }, error => {
      if (error.status === 404) {
        swal('Warning', 'Ce test est obligatoire, pour pouvoir compléter votre dossier, il faut passer ce test', 'warning');
        this.router.navigateByUrl('test-psychometrique')
      }
    })
  }
}

export class NavigationMain {
  public name: string;
  public icon: string;
  public active?: string;
  public childrens?: ChildrenNavigation[] = [];
  public url?: string;
  public visible?: boolean;
  public notification?: number;
}

export class ChildrenNavigation {
  public name: string;
  public active?: string;
  public url?: string;
  public notification?: number;
  public action?: any;
  public hidden?: boolean;
}
