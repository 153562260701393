/**
 * Created by Abbes on 10/07/2017.
 */
/**
 * Created by Abbes on 30/06/2017.
 */
import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Student} from "../models/student";
import {Country} from "../models/country";
import {City} from "../models/city";
import {environment} from "../../../environments/environment";

/**
 * Created by Abbes on 30/06/2017.
 */
@Injectable()
export class InscriptionService extends GenericService {

  constructor(private http: HttpClient) {
    super();
  }


  getAllCountries() {
    const url = environment.baseUrl + "/geo/countries";
    const headers = this.headers;

    return this.http.get<Country[]>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  getCitiesByCountry(countryId: string) {
    const url = environment.baseUrl + "/geo/countries/" + countryId + "/cities";
    const headers = this.headers;

    return this.http.get<City[]>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  registerStudent(student: Student) {
    const url = environment.baseUrl + "/student/create";
    const headers = this.headers;

    return this.http.post(url, JSON.stringify(student),
      {
        headers: headers
      }).pipe(
      catchError(this.handleErrors)
    )
  }


}
