import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {Student} from "../../shared/models/student";
import {Country} from "../../shared/models/country";
import {InscriptionService} from "../../shared/services/inscription.service";
import {City} from "../../shared/models/city";
import {Utils} from "../../shared/utils";
import {University} from "../../shared/models/university";
import {Subscription} from "rxjs/Subscription";
import {StorageService} from "../../shared/services/storage.service";
import {StudentFileService} from "app/shared/services/student-file.service";
import {UserService} from "../../shared/services/user.service";
import {PassportStudent} from "../../shared/models/Passport_Student";
import {CinStudent} from "../../shared/models/cinStudent";

declare var jQuery: any;
declare var swal: any;

@Component({
  templateUrl: './student-registration.component.html',
  styleUrls: ['./student-registration.component.css'],
})
export class StudentRegistrationComponent implements OnInit {


  student: Student = new Student();
  busy: Subscription;
  countries: Country[] = [];
  cities: City[] = [];
  citiesNaissance: City[] = [];
  citiesCIN: City[] = [];
  citiesPassport: City[] = [];
  submitted: boolean;
  universities: University[] = [];
  editAction: boolean;
  uni_years: string[] = [];
  reglementApproved: boolean = false;
  isShowModal: boolean;
  isNationalityExist: boolean;

  isNotMedecin: boolean;


  ngOnInit() {

    this.isNotMedecin = this.router.url.indexOf('nm') !== -1;
    const baseContext = this;
    this.editAction = this.router.url.indexOf("edit") !== -1;
    this.uni_years = Utils.getUniversityYears(1990);
    if (this.editAction) {
      this.settingInformation();
    }
    this.initializeDates();
    this.initializeRadioBox();
    this.initializeSelect2();
    this.getAllCountries();

    this.getAllUniversities();
  }


  constructor(private router: Router,
              private studentFileService: StudentFileService,
              private inscriptionService: InscriptionService,
              private stoarageService: StorageService,
              private userService: UserService) {
  }

  initializeRadioBox() {
    // Default initialization
    const baseContext = this;
    jQuery(".radioBox-sex").uniform({
      radioClass: 'choice'
    });
    setTimeout(function () {
      jQuery(".radioBox-oriented").uniform({
        radioClass: 'choice'
      });
      jQuery(".radioBox-oriented").on("change", function () {
        baseContext.student.oriented = jQuery(this).val();
      });
    }, 5);

    jQuery(".radioBox-civil").uniform({
      radioClass: 'choice'
    });

    if (baseContext.student.sex) {
      jQuery('#' + baseContext.student.sex).prop('checked', true);
      jQuery.uniform.update('#' + baseContext.student.sex);
    }
    if (baseContext.student.oriented) {
      jQuery('#' + baseContext.student.oriented).prop('checked', true);
      jQuery.uniform.update('#' + baseContext.student.oriented);
    }
    if (baseContext.student.civil_status) {
      console.log("Etat civil");
      console.log(baseContext.student.civil_status);
      jQuery('.radioBox-civil[value=' + baseContext.student.civil_status + ']').prop('checked', true);
      jQuery.uniform.update('.radioBox-civil[value=' + baseContext.student.civil_status + ']');
    }
    jQuery(".radioBox-sex").on("change", function () {
      baseContext.student.sex = jQuery(this).val();
    });
    jQuery(".radioBox-civil").on("change", function () {
      baseContext.student.civil_status = +jQuery(this).val();
    });
  }


  settingInformation() {
    this.student = <Student>this.stoarageService.read("student");
    this.isNotMedecin = this.student.medecin === 0;
    if (!this.student.cin)
      this.student.cin = new CinStudent();
    if (!this.student.passport)
      this.student.passport = new PassportStudent();
    this.student.label_address = this.student.adress.label_address;
    this.student.address_city = this.student.adress.id_adress;
    this.student.postal_code = this.student.adress.postal_code;
    if (this.student.oriented) {
      jQuery(".checkbox").prop('checked', true).uniform('refresh');
    }
    this.initializeDates();

    //  this.initializeSelect2();
  }

  initializeDates() {
    const baseContext = this;
    /* Date de naissance */
    const dateNaissance = jQuery('.date_naissance');
    dateNaissance.daterangepicker({
      "singleDatePicker": true,
      "showDropdowns": true,
      "locale": {
        "format": "DD/MM/YYYY"
      }
    });

    dateNaissance.on("change", function () {
      baseContext.student.birthday = Utils.convertDateServer(jQuery(".date_naissance").val());
    });

    if (this.editAction) {
      dateNaissance.val(Utils.convertDate(this.student.birthday)).trigger("change");
    }

    /* Manage Date CIN */
    const dateCIN = jQuery('.date_emition_cin');

    dateCIN.daterangepicker({
      "singleDatePicker": true,
      "showDropdowns": true,
      "locale": {
        "format": "DD/MM/YYYY"
      }
    });

    dateCIN.on("change", function () {
      baseContext.student.cin.date = Utils.convertDateServer(dateCIN.val());
    });

    if (this.editAction) {
      dateCIN.val(Utils.convertDate(this.student.cin.date)).trigger("change");
    }
    /* Manage Date Passport */
    const datePassport = jQuery('.date_emition_passport');
    datePassport.daterangepicker({
      "singleDatePicker": true,
      "showDropdowns": true,
      "locale": {
        "format": "DD/MM/YYYY"
      }
    });

    datePassport.on("change", function () {
      baseContext.student.passport.date = Utils.convertDateServer(datePassport.val());
    });

    if (this.editAction) {
      datePassport.val(Utils.convertDate(this.student.passport.date)).trigger("change");
    }
  }


  getAllCountries() {
    const baseContext = this;
    const paysSelect = jQuery(".select-pays");
    const paysSelectNaissance = jQuery(".select-pays-naissance");

    const paysCIN = jQuery(".select-pays-cin");
    const paysPassport = jQuery(".select-pays-passport");


    const nationalitySelect = jQuery(".select-pays-nationality");
    this.inscriptionService.getAllCountries()
      .subscribe(
        (data) => {
          this.countries = data;


          if (this.editAction) {
            setTimeout(function () {
              paysSelect.val(baseContext.student.adress.city.CountryCode).trigger("change");
            }, 50);
            setTimeout(function () {
              nationalitySelect.val(baseContext.student.nationality).trigger("change");
            }, 50);
            setTimeout(function () {
              paysSelectNaissance.val(baseContext.student.city_birth.CountryCode).trigger("change");
            }, 50);
            if (baseContext.student.cin.city)
              setTimeout(function () {
                paysCIN.val(baseContext.student.cin.city.CountryCode).trigger("change");
              }, 50);
            if (baseContext.student.passport.city)
              setTimeout(function () {
                paysPassport.val(baseContext.student.passport.city.CountryCode).trigger("change");
              }, 50);
          }

        },
        (error) => {

        }
      )


  }

  isValidChamps() {
    if (!this.student.first_name
      || !this.student.last_name
      || !this.student.birthday
      || !this.student.address_city
      || !this.student.birthday_city
      || (!this.student.cin.code && !this.student.passport.code)
      || (this.student.cin.code && (!this.student.cin.id_city || !this.student.cin.date))
      || (this.student.passport.code && (!this.student.passport.id_city || !this.student.passport.date))
      || !this.student.email || !this.student.mobile || !this.student.label_address
      || (!this.isNotMedecin && !this.student.oriented)
      || (!this.isNotMedecin && !this.student.study_access_year)
      || !this.student.first_name_arabe
      || !this.student.last_name_arabe
      || (!this.student.nationality)
      || (!this.student.postal_code)
      || (this.student.nationality && !this.student.nationalityLabel)
      || (!this.editAction && this.student.email !== this.student.confirmEmail)) {
      return false;
    }
    return true;
  }

  openModalreglement() {
    const baseContext = this;
    this.submitted = true;
    console.log(this.student);

    if (!this.isValidChamps()) {
      return;
    }


    baseContext.reglementApproved = false;
    jQuery("#modal_form_vertical").modal();

  }

  public registerStudent() {
    this.submitted = true;
    jQuery("#modal_form_vertical").modal("hide");

    console.log(this.student);

    if (!this.isValidChamps()) {
      return;
    }

    if (!this.editAction) {
      const baseContext = this;
      this.isShowModal = false;

      this.student.medecin = this.isNotMedecin ? 0 : 1;
      this.busy = this.inscriptionService.registerStudent(this.student)
        .subscribe(
          (data) => {
            console.log(data);
            swal({
              title: "Succès!",
              text: 'Votre dossier a été créé, un e-mail contenant votre mot de passe d\'accès à la plateforme vous a été envoyé à votre adresse mail.',
              confirmButtonColor: "#66BB6A",
              type: "success"
            });
            this.router.navigate(["/login"]);
          },
          (error) => {
            if (error.status === 400 && error.error.email_confirmed === 0) {
              baseContext.student.id_student = error.error.data;
              swal({
                  title: "Ce compte est déja existant !!",
                  text: "Vous voulez supprimer l'ancien ?",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#EF5350",
                  confirmButtonText: "Oui, supprimer!",
                  cancelButtonText: "Non, annuler!",
                  closeOnConfirm: true,
                  closeOnCancel: true
                },
                function (isConfirm) {
                  if (isConfirm) {
                    baseContext.busy = baseContext.inscriptionService.registerStudent(baseContext.student).subscribe(
                      datatwo => {
                        swal({
                          title: "Succès!",
                          text: 'Votre dossier a été créé, un e-mail contenant votre mot de passe d\'accès à la plateforme vous a été envoyé à votre adresse mail.',
                          confirmButtonColor: "#66BB6A",
                          type: "success"
                        });
                      }, errortwo => {
                        swal({
                          title: "Erreur!",
                          text: errortwo.error.response,
                          type: "error"
                        });
                      });
                  } else {
                    swal({
                      title: "Annulé",
                      text: "Vous avez annulé cette action",
                      confirmButtonColor: "#2196F3",
                      type: "error"
                    });
                  }
                });
            } else if (error.status === 403) {
              swal({
                title: "Erreur!",
                text: "Probléme dans l'email , verifier le !!!",
                type: "error"
              });
            } else {
              swal({
                title: "Erreur!",
                text: error.error.response,
                type: "error"
              });
            }
          }
        );
    } else {

      this.busy = this.studentFileService.editInformations(this.student)
        .subscribe(
          (data) => {
            data.isNew = Utils.verifyNewStudent(data.study_access_year);
            this.stoarageService.write("student", data);
            this.userService.loggedUser = data;
            swal({
              title: "Succès!",
              text: 'Votre profil a été modifié avec succès.',
              confirmButtonColor: "#66BB6A",
              type: "success"
            });
            this.router.navigate(["/student-file"]);
          },
          (error) => {
            swal({
              title: "Erreur!",
              text: JSON.parse(error._body).response,
              type: "error"
            });
          });
    }


  }

  public initializeSelect2() {
    const villeSelectNaissance = jQuery(".select-ville-naissance");
    const paysSelectNaissance = jQuery(".select-pays-naissance");
    const selectNationality = jQuery(".select-pays-nationality");
    const paysSelect = jQuery(".select-pays");
    const villeSelect = jQuery(".select-ville");


    villeSelectNaissance.select2();
    const baseContext = this;
    villeSelectNaissance.on("change", function () {
      baseContext.student.birthday_city = +villeSelectNaissance.val();
    });


    paysSelectNaissance.select2();

    paysSelectNaissance.on("change", function () {
      baseContext.inscriptionService.getCitiesByCountry(paysSelectNaissance.val())
        .subscribe(
          (data) => {
            baseContext.citiesNaissance = data;
            if (baseContext.editAction) {
              setTimeout(function () {
                villeSelectNaissance.val(baseContext.student.city_birth.id_City).trigger("change");
              }, 100);
            }
          },
          (error) => {

          }
        )
    })


    paysSelect.select2();


    villeSelect.select2();
    villeSelect.on("change", function () {
      baseContext.student.address_city = +villeSelect.val();
    });
    paysSelect.on("change", function () {
      baseContext.inscriptionService.getCitiesByCountry(paysSelect.val())
        .subscribe(
          (data) => {
            baseContext.cities = data;

            if (baseContext.editAction) {
              setTimeout(function () {
                villeSelect.val(baseContext.student.adress.city.id_City).trigger("change");
              }, 50);
            }
          },
          (error) => {

          }
        )
    })

    /* University Year */
    setTimeout(function () {
      const universityYear = jQuery(".select-university-year");
      universityYear.select2();
      universityYear.on("change", function () {
        baseContext.student.study_access_year = jQuery(this).val();
        if (baseContext.editAction) {
          universityYear.val(baseContext.student.study_access_year).trigger("change");
        }
      });
    }, 10);
    /* Nationality */
    selectNationality.select2();
    selectNationality.on("change", function () {
      baseContext.student.nationality = jQuery(this).val();
      let i;
      for (i = 0; i < baseContext.countries.length; i++) {
        if (baseContext.countries[i].Code === baseContext.student.nationality) {
          baseContext.student.nationalityLabel = baseContext.countries[i].nationality;
          break;
        }
      }
      baseContext.isNationalityExist = baseContext.student.nationalityLabel != null;
    });

    /* Manage CIN & Passport */
    const paysCIN = jQuery(".select-pays-cin");
    const paysPassport = jQuery(".select-pays-passport");
    const villeCIN = jQuery(".select-ville-cin");
    const villePassport = jQuery(".select-ville-passport");

    paysCIN.select2();
    paysPassport.select2();
    villeCIN.select2();
    villePassport.select2();

    paysCIN.on("change", function () {
      baseContext.inscriptionService.getCitiesByCountry(paysCIN.val())
        .subscribe(
          (data) => {
            baseContext.citiesCIN = data;
            if (baseContext.editAction) {
              setTimeout(function () {
                villeCIN.val(baseContext.student.cin.id_city).trigger("change");
              }, 100);
            }
          },
          (error) => {

          }
        )
    });
    villeCIN.on("change", function () {
      baseContext.student.cin.id_city = +villeCIN.val();
    });


    paysPassport.on("change", function () {
      baseContext.inscriptionService.getCitiesByCountry(paysPassport.val())
        .subscribe(
          (data) => {
            baseContext.citiesPassport = data;
            if (baseContext.editAction) {
              setTimeout(function () {
                villePassport.val(baseContext.student.passport.id_city).trigger("change");
              }, 100);
            }
          },
          (error) => {

          }
        )
    });

    villePassport.on("change", function () {
      baseContext.student.passport.id_city = +villePassport.val();
    });

  }


  initializeUniversitySelect2() {
    const baseContext = this;

    setTimeout(function () {
      const selectUniversity = jQuery(".select-university");
      selectUniversity.select2();

      selectUniversity.on("change", function () {
        baseContext.student.id_origin_university = +selectUniversity.val();
      });


    }, 20);


  }

  private getAllUniversities() {
    const selectUniversity = jQuery(".select-university");
    const baseContext = this;
    this.studentFileService.getAllUniversities()
      .subscribe(
        (data) => {
          this.universities = data;
          setTimeout(function () {
            const selectUniversity = jQuery(".select-university");
            selectUniversity.val(baseContext.student.id_origin_university).trigger("change");
          }, 50);

        },
        (error) => {

        }
      )
  }

  goStudentFile() {
    this.router.navigate(["/student-file"]);
  }
}


