import {Component, OnInit} from '@angular/core';
import {MseminarService} from "../../shared/services/mseminar.service";
import {Subscription} from "rxjs";
import {SeminarSession} from "../../shared/models/mseminar/SeminarSession";
import {Seminar} from "../../shared/models/mseminar/Seminar";
import {SeanceSeminar, SeanceUI} from "../../shared/models/mseminar/SeanceSeminar";
import {Utils} from "../../shared/utils";
import {InscriptionSeminar} from "../../shared/models/mseminar/InscriptionSeminar";
import * as FileSaver from "file-saver";

declare var swal: any;

@Component({
  selector: 'app-mini-seminar',
  templateUrl: './mini-seminar.component.html',
  styleUrls: ['./mini-seminar.component.css']
})
export class MiniSeminarComponent implements OnInit {

  busy: Subscription;

  session: SeminarSession;

  seminars: Seminar[] = [];

  seances: any[] = [];

  userChoices: InscriptionSeminar[] = [];

  constructor(private mseminarService: MseminarService) {
    this.session = new SeminarSession();
  }

  ngOnInit() {
    this.getSessionSeminarByStudent();
  }

  getSessionSeminarByStudent() {
    this.busy = this.mseminarService.getSessionSeminarByStudent()
      .subscribe(
        (data: any) => {
          this.session = data.session;
          this.userChoices = data.inscriptions;
          this.seminars = Utils.filterObjectsDistinct(this.session.seances, 'seminar', 'id_seminar');
          this.seances = Utils.groupByM(this.session.seances, function (item) {
            return [item.start_date];
          });

          this.seances.sort((a, b) => {
            return a[0].start_date > b[0].start_date ? 1 : (a[0].start_date < b[0].start_date ? -1 : 0);
          });
        },
        (error) => {

        }
      )



  }

  validerChoice() {
    this.busy = this.mseminarService.inscriptionMS(this.session.id_seminar_session, this.userChoices)
      .subscribe(
        (data) => {

          swal("Succées", "Inscription validé avec succées", "success");
        },
        (error) => {

        }
      )
  }

  getSeanceId(seances: SeanceSeminar[], seminar: Seminar) {
    for (let i = 0; i < seances.length; i++) {
      if (seances[i].id_seminar === seminar.id_seminar) {
        return seances[i].id_seance_seminar;
      }
    }
    return 0;
  }

  checkExistSeance(seances: SeanceSeminar[], seminar: Seminar) {
    for (let i = 0; i < seances.length; i++) {
      if (seances[i].id_seminar === seminar.id_seminar) {
        if (seances[i].seminar.capacity <= seances[i].nb_inscriptions)
          return -1;
        return 1;
      }
    }
    return 0;
  }

  checkSelectedUser(seanceId) {
    for (let i = 0; i < this.userChoices.length; i++) {
      if (this.userChoices[i].id_seance_seminar === seanceId) {
        return true;
      }
    }
    return false;
  }

  inscriptionMS(seminar, seances) {

    const seanceId = this.getSeanceId(seances, seminar);

    const date = seances[0].start_date;

    let indexDate = -1;
    let indexSem = -1;
    for (let i = 0; i < this.userChoices.length; i++) {
      if (this.userChoices[i].date === date) {
        indexDate = i;
      }
      if (this.userChoices[i].id_seminar === seminar.id_seminar) {
        indexSem = i;
      }
    }
    if (indexDate !== -1) {
      this.userChoices.splice(indexDate, 1);
      indexSem = indexSem === 0 ? indexSem : indexSem - 1;
    }
    if (indexSem !== -1) {
      this.userChoices.splice(indexSem, 1);
    }

    if (seanceId > 0) {
      const userChoice = new InscriptionSeminar();
      userChoice.id = Utils.getRandomString();
      userChoice.id_seance_seminar = seanceId;
      userChoice.date = seances[0].start_date;
      userChoice.id_seminar = seminar.id_seminar;
      this.userChoices.push(userChoice);
    }
  }

  generatePDFpresence() {

    this.busy = this.mseminarService.generatePresencePDF(this.session.id_seminar_session)
      .subscribe(
        (data) => {
          FileSaver.saveAs(data, "Présence_Mini_Seminaire.pdf");
        },
        (error) => {

        }
      )
  }
}
