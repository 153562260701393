import {NgModule} from "@angular/core";
import {NgBusyModule} from "ng-busy";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {StorageService} from "./services/storage.service";
import {HeaderNavigationComponent} from "./UI/header-navigation/header-navigation";
import {StudentRegistrationComponent} from "../registration/student/student-registration.component";
import {ValidationNoteComponent} from "../student-file/shared/validation-note.component";
import {MomentModule} from "ngx-moment";
import 'moment/locale/fr'
import {NgSelect2Component} from "./UI/ng-select2/ng-select2.component";
import {NgEmogiComponent} from "./UI/ng-emogi/ng-emogi.component";
import {RoundPipe} from "./pipes/round.pipe";
import {NgNbdocumentsComponent} from "../enseignement/details-evaluation/shared/ng-nbdocuments/ng-nbdocuments.component";
import {NgValidPortfolioComponent} from "../enseignement/details-evaluation/shared/ng-valid-portfolio/ng-valid-portfolio.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgBusyModule,
    MomentModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    NgBusyModule,
    MomentModule,
    HeaderNavigationComponent,
    ValidationNoteComponent,
    NgSelect2Component,
    NgEmogiComponent,
    RoundPipe,
    NgNbdocumentsComponent,
    NgValidPortfolioComponent
  ],
  declarations: [
    HeaderNavigationComponent,
    StudentRegistrationComponent,
    ValidationNoteComponent,
    NgSelect2Component,
    NgEmogiComponent,
    RoundPipe,
    NgNbdocumentsComponent,
    NgValidPortfolioComponent
  ],
  providers: [StorageService]
})
export class SharedModule {

}
