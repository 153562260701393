/**
 * Created by AHMED on 03/08/2017.
 */
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {StageComponent} from "./stage/stage.component";
import {DetailsEvaluationComponent} from "./details-evaluation/details-evaluation.component";


export const routes: Routes = [
  {
    path: 'stage',
    component: StageComponent,
  },
  {
    path: 'details-evaluation',
    component: DetailsEvaluationComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EnseignementModuleRouting {
}
