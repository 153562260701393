import {NgModule} from "@angular/core";

import {AppComponent} from "./app.component";
import {LoginComponent} from "./login/login.component";
import {AppRoutingModule} from "./app.routing";
import {FullLayoutComponent} from "./layouts/full-layout.component";
import {AuthService} from "./shared/services/auth.service";
import {StorageService} from "./shared/services/storage.service";
import {HttpClientModule} from "@angular/common/http";
import {CommonModule, HashLocationStrategy, LocationStrategy} from "@angular/common";
import {NgBusyModule} from "ng-busy";
import {FormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {InscriptionService} from "./shared/services/inscription.service";
import {ManageStudentFileModule} from "./student-file/student-file.module";
import {SharedModule} from "./shared/shared.module";
import {StudentFileService} from "./shared/services/student-file.service";
import {UserService} from "./shared/services/user.service";
import {NotFoundComponent} from "./error/not-found.component";
import {SupportModule} from "./support/support.module";
import {ReclamationService} from "./shared/services/reclamation.service";
import {InscriptionsModule} from "./inscriptions/inscriptions.module";
import {EcosService} from "./shared/services/ecos.service";
import {ErrorModule} from "./error/error.module";
import {ConversationService} from "./shared/services/conversation.service";
import {NotificationService} from "./shared/services/notification.service";
import {HeaderNotificationComponent} from "./layouts/notifications/header-notification.component";
import {PartielNotificationService} from "./shared/services/partielNotification.service";
import {StageService} from "./shared/services/stage.service";
import {EnseignementModule} from "./enseignement/enseignement.module";
import {PostGraduateTrainingService} from "./shared/services/post-graduate-training.service";
import {TestPsychometriqueComponent} from "./test-psychometrique/test-psychometrique.component";
import {OptionalUnitService} from "./shared/services/optional-unit.service";
import {SessionExamenService} from "./shared/services/sessionExamen.service";
import {MseminarService} from "./shared/services/mseminar.service";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FullLayoutComponent,
    NotFoundComponent,
    HeaderNotificationComponent,
    TestPsychometriqueComponent
  ],
  imports: [
    SharedModule,
    NgBusyModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ManageStudentFileModule,
    SupportModule,
    InscriptionsModule,
    ErrorModule,
    EnseignementModule
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    AuthService,
    StorageService,
    InscriptionService,
    StudentFileService,
    UserService,
    ReclamationService,
    EcosService,
    ConversationService,
    NotificationService,
    PartielNotificationService,
    StageService,
    SessionExamenService,
    PostGraduateTrainingService,
    OptionalUnitService,
    MseminarService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
