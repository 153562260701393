import {Component, OnInit} from '@angular/core';
import {UserService} from "../../shared/services/user.service";
import {Subscription} from "rxjs/Subscription";

@Component({
  selector: 'app-style-cognitif',
  templateUrl: './style-cognitif.component.html',
  styleUrls: ['./style-cognitif.component.css']
})
export class StyleCognitifComponent implements OnInit {
  X = 0;
  Y = 0;
  busy: Subscription

  constructor(private userService: UserService) {
  }

  ngOnInit() {
    this.busy = this.userService.getUserTestPsychometrique().subscribe(data => {
      this.X = data.x;
      this.Y = data.y;
    }, error => {

    })
  }

}
