/**
 * Created by AHMED on 03/08/2017.
 */
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EcosComponent} from "./ecos/ecos.component";
import {MenuComponent} from "./menu/menu.component";
import {InscriptionsUniversityYearComponent} from "./university-year/inscriptions-university-year.component";
import {SessionStageComponent} from "./stage/session-stage/session-stage.component";
import {GroupeChoiceComponent} from "./stage/groupe-choice/groupe-choice.component";
import {ServiceChoiceComponent} from "./stage/service-choice/service-choice.component";
import {PostGraduateTrainingComponent} from "./post-graduate-training/post-graduate-training.component";
import {OptionalUnitChoiceComponent} from "./optional-unit-choice/optional-unit-choice.component";
import {MiniSeminarComponent} from "./mini-seminar/mini-seminar.component";


export const routes: Routes = [
  {
    path: '',
    component: MenuComponent,
    children: [
      {
        path: 'ecos',
        component: EcosComponent
      },
      {
        path: 'university-year',
        component: InscriptionsUniversityYearComponent
      },
      {
        path: 'post-graduate-training',
        component: PostGraduateTrainingComponent
      },
      {
        path: 'optional-unit-choice',
        component: OptionalUnitChoiceComponent
      },
      {
        path: 'mini-seminaire',
        component: MiniSeminarComponent
      },
      {
        path: 'stage',
        children: [
          {
            path: 'session-stage',
            component: SessionStageComponent
          },
          {
            path: 'groupe-choice/:sessionstageId',
            component: GroupeChoiceComponent
          },
          {
            path: 'service-choice/:sessionstageId',
            component: ServiceChoiceComponent
          }
        ]
      }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InscriptionsModuleRouting {
}
