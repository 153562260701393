import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {StorageService} from "./storage.service";
import {Student} from "../models/student";
import {Session} from "../models/session"
import {EtudiantExamen} from "../models/ecos/EtudiantExamen";
import {environment} from "../../../environments/environment";

/**
 * Created by AHMED on 04/08/2017.
 */
@Injectable()
export class EcosService extends GenericService {

  constructor(private http: HttpClient, private stoarageService: StorageService) {
    super();
  }

  getResultats() {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("student-token"));
    const url = environment.baseUrl + "/student/me/getResultatsECOSByEtudiant";

    return this.http.get<EtudiantExamen[]>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  getSessionsByNiveauIdAndAfterToday(niveauId: Number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("student-token"));
    const url = environment.baseUrl + "/student/me/session/allByNiveauAndAfterDate/" + niveauId;

    return this.http.get<Session[]>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  getSessionsActives() {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("student-token"));
    const url = environment.baseUrl + "/student/me/session/active";

    return this.http.get<Session[]>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  getAllExamsBySession(id_Session: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("student-token"));
    const url = environment.baseUrl + "/student/me/session/" + id_Session + "/examen";

    return this.http.get<any>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  inscriptionExamen(id_Session: number, id_Examen: number, student: Student) {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("student-token"));
    const url = environment.baseUrl + "/student/me/session/" + id_Session + "/examen/" + id_Examen + "/inscrire";
    return this.http.post(url, {"student": {"id_student": student.id_student}}, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )

  }


}
