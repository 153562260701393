import {Component, OnInit} from "@angular/core";
import {NotificationService} from "../../shared/services/notification.service";
import {Notification} from "../../shared/models/notification";

@Component({
  selector: 'app-header-notification',
  templateUrl: 'header-notification.component.html'
})
export class HeaderNotificationComponent implements OnInit {
  notifications: Notification[] = [];

  constructor(private notificationService: NotificationService) {

  }

  ngOnInit(): void {
    this.notificationService.getAllStudentNotifications().subscribe(data => {
      this.notifications = data;
    });
  }

  getNotificationLenght() {
    let count = 0;
    this.notifications.forEach(function (notification: Notification) {
      if (!notification.viewed) {
        count++;
      }
    });
    return count;
  }

  setAllNotificationViewed() {
    const baseContext = this;
    this.notifications.forEach(function (notification: Notification) {
      if (!notification.viewed) {
        baseContext.notificationService.setNotficationViewed(notification.id_Notification).subscribe();
      }
    });
  }

  switchNavCollapseMode() {
    this.notificationService.isNavCollapsed = !this.notificationService.isNavCollapsed
  }
}
