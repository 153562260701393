import {Critere} from "./Critere";
import {Critere_Etudiant} from "./Critere_Etudiant";
import {Evaluation} from "./Evaluation";

export class Evaluation_Critere_Etudiant{
  public evaluation_critere_etudiant_id: number;
  public value: number;
  public evaluation_id: number;
  public critere_etudiant_id: number;
  public critere_etudiant: Critere_Etudiant;
  public evaluation: Evaluation;
}
