import {StorageService} from "app/shared/services/storage.service";
import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Student} from "../models/student";
import {Observable} from "rxjs/Observable";
import {environment} from "../../../environments/environment";

@Injectable()
export class UserService extends GenericService {
  loggedUser: Student;
  nbr_sessions: number;

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
    this.loggedUser = <Student> storageService.read('student');
  }

  getLoggedUser() {
    const headers = this.headers.set("Authorization", "Bearer " + this.getTokent());
    const url = environment.baseUrl + "/student/me";

    return this.http.get<any>(url, {
      headers: headers
    }).pipe(catchError(this.handleErrors))
  }


  getTokent() {
    return <string>this.storageService.read("student-token")
  }

  generateQRcodeStudent() {
    const headers = this.headers.set("Authorization", "Bearer " + this.getTokent());
    const url = environment.baseUrl + "/student/me/generateQrCode";

    return this.http.get(url, {
      headers: headers,
      responseType: 'blob'
    })
      .pipe(catchError(this.handleErrors))
  }

  forgotPassword(email: string) {
    const url = environment.baseUrl + "/auth/password/reset";

    return this.http.post(url, {
      email: email
    })
      .pipe(catchError(this.handleErrors))
  }

  getInscriptionByStudent(currentUniversityYear: string, cycle: number, studentId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));
    const url = environment.baseUrl + "/student/me/registration/" + studentId + "/information";

    return this.http.post(url, {
        year_university: currentUniversityYear,
        cycle: cycle
      },
      {
        headers: headers
      })
      .pipe(map(res => res)
        , catchError(this.handleErrors))
  }

  inscrireYearUniversity(id_Registration_University: number, paymentMedia: string, specialityId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));
    const url = environment.baseUrl + "/student/me/registration";

    return this.http.post(url, {
        id_Registration_University: id_Registration_University,
        payment_media: paymentMedia,
        speciality_id: specialityId
      },
      {
        headers: headers
      })
      .pipe(catchError(this.handleErrors))
  }

  generateDetailPDFStudent() {
    const headers = this.headers.set("Authorization", "Bearer " + this.getTokent());
    const url = environment.baseUrl + "/student/me/generateDetailPDF";

    return this.http.get(url, {
      headers: headers,
      responseType: 'blob'
    })
      .pipe(catchError(this.handleErrors))
  }

  generationAttestationFr() {
    const headers = this.headers.set("Authorization", "Bearer " + this.getTokent());
    const url = environment.baseUrl + "/student/me/attestation-inscription/fr";

    return this.http.post(url, {}, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors))
  }

  checkIfUserIsRegistredUniversity() {
    const headers = this.headers.set("Authorization", "Bearer " + this.getTokent());
    const url = environment.baseUrl + "/student/me/is-inscrit";

    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors))
  }


  getUserTestPsychometrique() {
    const headers = this.headers.set("Authorization", "Bearer " + this.getTokent());
    const url = environment.baseUrl + "/student/me/test-psychometrique";

    return this.http.get<any>(url, {
      headers: headers
    }).pipe(catchError(this.handleErrors))
  }

  setUserTestPsychometrique(x: number, y: number): Observable<any> {
    const headers = this.headers.set("Authorization", "Bearer " + this.getTokent());
    const url = environment.baseUrl + "/student/me/test-psychometrique";

    return this.http.post<any>(url, {
      x: x,
      y: y
    }, {
      headers: headers
    }).pipe(catchError(this.handleErrors))
  }

  inscrireStudentFormation(id_inscription_formation: number, paymentMedia: string) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));
    const url = environment.baseUrl + "/student/me/registration/formation";

    return this.http.post(url, {
        id_inscription_formation: id_inscription_formation,
        payment_media: paymentMedia
      },
      {
        headers: headers
      })
      .pipe(catchError(this.handleErrors))
  }
}

interface OnConversationOpenObserver {
  onConversationOpenObserver(banqueId: number);
}
