import {Component, OnInit} from "@angular/core";
import {Student} from "../../shared/models/student";
import {UserService} from "../../shared/services/user.service";
import {EcosService} from "../../shared/services/ecos.service";
import {Session} from "../../shared/models/session";
import {Examen} from "../../shared/models/examen";
import {Subscription} from "rxjs/Subscription";
import {environment} from "../../../environments/environment";

declare var swal: any;
declare var jQuery;

@Component({
  templateUrl: 'ecos.component.html',
  styleUrls: [],

})
export class EcosComponent implements OnInit {
  selectedSession: Session;
  selectedExams: Examen[] = [];
  loggedUser: Student = new Student();
  sessionsActives: Session[] = [];
  baseURL: string;
  busy: Subscription;

  finalExams: Examen[] = [];

  ngOnInit() {
    this.loggedUser = this.userService.loggedUser;
    const baseContext = this;
    this.busy = baseContext.ecosService.getSessionsActives().subscribe(data => {
      baseContext.sessionsActives = data;
      baseContext.selectedSession = new Session();

    });
  }

  constructor(private userService: UserService, private ecosService: EcosService) {
    this.baseURL = environment.baseUrl;
    this.selectedSession = new Session();

  }


  openModalExamens(index: number) {
    const baseContext = this;
    this.selectedSession = this.sessionsActives[index];
    console.log("modal");
    console.log(index);
    this.getExamensBySession(this.selectedSession, index);
    jQuery("#modal_form_vertical").modal();
  }


  getExamensBySession(session: Session, index: number) {
    this.ecosService.getAllExamsBySession(session.id_Session)
      .subscribe(
        (examens) => {
          this.selectedExams = examens.data;
          console.log(this.selectedExams[0]);
          for (const examen of this.selectedExams) {
            if (examen.nbre_Places >= examen.max_Places
              && examen.etudiants_count === 0) {
              examen.etudiants_count = 2;
            }
          }
          // for (let i = 0; i < this.selectedExams.length; i++) {
          //   console.log(this.selectedExams[index]);
          //   if (this.selectedExams[index].nbre_Places >= this.selectedExams[index].max_Places
          //     && this.selectedExams[index].etudiants_count === 0) {
          //     this.selectedExams[index].etudiants_count = 2;
          //   }
          // }

          this.finalExams = this.getFinalExams(this.selectedExams);
        },
        (error) => {

        }
      )
  }


  inscriptionExamen(index: number, examen: Examen) {
    console.log("modal");
    const baseContext = this;
    const btnStatus = jQuery('.btn-status-' + index);
    const iconStatus = jQuery('.icon-status-' + index);
    this.busy = this.ecosService.inscriptionExamen(examen.session.id_Session,
      examen.id_Examen, this.loggedUser)
      .subscribe(
        (data) => {
          examen.etudiants_count = 1;
          btnStatus.removeClass('bg-teal-400').addClass('bg-grey-400');
          btnStatus.value = "inscrit";
          btnStatus.addClass('disabled');
          iconStatus.removeClass('icon-circle-right2').addClass('icon-check');
          swal({
            title: "Succès!",
            text: 'Inscription avec succès ',
            confirmButtonColor: "#66BB6A",
            type: "success"
          });
        },
        (error) => {
          swal('Warning', 'Vous ne pouvez pas vous inscrire dans cette session.', 'warning');
        }
      )

  }

  private getFinalExams(selectedExams: Examen[]) {
    const result = [];
    console.log(selectedExams);
    selectedExams.forEach(
      exam => {
        if (exam.max_Places > exam.nbre_Places) {
          const indexStage = result.map(
            examItem => {
              return examItem.id_Stage
            }
          ).indexOf(exam.id_Stage);
          if (indexStage === -1) {
            result.push(exam);
          }
        }
      }
    );
    return result;
  }
}
