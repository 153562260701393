import {Component, OnInit} from "@angular/core";

import {Subscription} from "rxjs/Subscription";
import {Conversation} from "../../../shared/models/Conversation";
import {ConversationService} from "../../../shared/services/conversation.service";
import {ActivatedRoute} from "@angular/router";
import {UserService} from "../../../shared/services/user.service";
import {environment} from "../../../../environments/environment";

declare let swal;
declare let jQuery;

@Component({
  templateUrl: 'details-discussion.component.html',
  styleUrls: [],

})
export class DetailsDiscussionComponent implements OnInit {


  busy: Subscription;
  conversation: Conversation;
  baseUrl = environment.baseUrl + '/';
  messageContent: string;

  constructor(private conversationServices: ConversationService, private  route: ActivatedRoute, private userService: UserService) {
    this.conversation = new Conversation();
  }

  ngOnInit() {
    this.getConersation();
  }

  private getConersation() {
    this.route.params.subscribe(params => {
      this.conversation.id_Conversation = +params["conversationId"];
      this.busy = this.conversationServices.getConversationById(this.conversation.id_Conversation).subscribe(data => {
        if (data.id_Student !== this.userService.loggedUser.id_student) {
          swal({
            title: "Non autorisé!",
            text: 'Vous ne pouvez pas voir cette conversation',
            type: "error"
          });
          return;
        }
        this.conversation = data;
        if (this.conversation.latest_message.id_Admin) {
          this.conversationServices.setConversationViewed(this.conversation).subscribe();
        }
      })
    });
  }

  public sendMessage() {
    this.busy = this.conversationServices.addConversationMessage(this.conversation, this.messageContent).subscribe(data => {
      this.conversation = data;
      this.messageContent = '';
      swal({
        title: "Succés!",
        text: 'Message envoyé avec succès',
        confirmButtonColor: "#66BB6A",
        type: "success"
      });
    })
  }

  public updateConversationStatus(status: number) {
    this.busy = this.conversationServices.updateConversationStatus(this.conversation, status).subscribe(data => {
      this.conversation.status = status;
      swal({
        title: "Succés!",
        text: 'Message status est changé avec succès',
        confirmButtonColor: "#66BB6A",
        type: "success"
      });
    })
  }
}
