import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {HttpClient} from "@angular/common/http";
import {StorageService} from "./storage.service";
import {catchError} from "rxjs/operators";
import {Observable} from "rxjs/Observable";
import {environment} from "../../../environments/environment";
import {Seminar} from "../models/mseminar/Seminar";
import {SeminarSession} from "../models/mseminar/SeminarSession";
import {InscriptionSeminar} from "../models/mseminar/InscriptionSeminar";

@Injectable()
export class MseminarService extends GenericService {


  baseUrl = environment.baseUrl + '/student/mini-seminar';

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }

  getSessionSeminarByStudent() {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.STUDENT_TOKEN));
    return this.http.get(this.baseUrl + "/session-choice/active",
      {headers: headers})
      .pipe(catchError(this.handleErrors));
  }

  inscriptionMS(seminarSessionId: number, userChoices: InscriptionSeminar[]) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.STUDENT_TOKEN));
    return this.http.post(this.baseUrl + "/session-choice/" + seminarSessionId + "/inscription", userChoices,
      {headers: headers})
      .pipe(catchError(this.handleErrors));
  }

  generatePresencePDF(seminarSessionId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.STUDENT_TOKEN));
    const url = this.baseUrl + "/session-choice/" + seminarSessionId + "/generate-presence-pdf";

    return this.http.get(url, {
      headers: headers,
      responseType: 'blob'
    })
      .pipe(catchError(this.handleErrors))
  }
}
