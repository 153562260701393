import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StyleCognitifComponent} from "./style-cognitif/style-cognitif.component";
import {MenuComponent} from "./menu/menu.component";
import {ResultMasteresComponent} from "./result-masteres/result-masteres.component";
import {ResultStagesComponent} from "./result-stages/result-stages.component";
import {DetailsResultStageComponent} from "./details-result-stage/details-result-stage.component";
import {ResultEcosComponent} from "./result-ecos/result-ecos.component";
import {ResultSessionExamensComponent} from "./result-session-examens/result-session-examens.component";
import {ResultUoComponent} from "./result-uo/result-uo.component";

export const routes: Routes = [
  {
    path: '',
    component: MenuComponent,
    children: [
      {
        path: '',
        redirectTo: './style-cognitif'
      },
      {
        path: 'style-cognitif',
        component: StyleCognitifComponent
      },
      {
        path: 'result-masteres',
        component: ResultMasteresComponent
      },
      {
        path: 'result-stages',
        component: ResultStagesComponent
      },
      {
        path: 'result-ecos',
        component: ResultEcosComponent
      },
      {
        path: 'result-session-examens',
        component: ResultSessionExamensComponent
      },
      {
        path: 'details-result-stages',
        component: DetailsResultStageComponent
      },
      {
        path : 'result-uo',
        component : ResultUoComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortfolioModuleRouting {
}
