import {Component, OnInit} from "@angular/core";
import {Subscription} from "rxjs/Subscription";
import {TestPsychometriqueItem} from "../shared/models/test.psychometrique.item";
import {TestScoreMatrixItem} from "../shared/models/test.score.matrix.item";
import {UserService} from "../shared/services/user.service";

declare let jQuery: any;
declare let swal: any;
declare let d3: any;

@Component({
  templateUrl: 'test-psychometrique.component.html',
  styleUrls: []
})
export class TestPsychometriqueComponent implements OnInit {


  busy: Subscription;
  testPsychometriqueContents: Array<TestPsychometriqueItem> = [
    {
      "order": 1,
      "title": "Quand j’utilise un nouvel appareil (ordinateur, magnétoscope …)",
      "responses": [{
        "index": "A",
        "label": "J'analyse soigneusement le mode d'emploi et j'essaie de bien comprendre" +
        " le fonctionnement de chaque élément.\n",
        chosenRange: 0
      }, {
        "index": "B",
        "label": "Je procède par essais et erreurs, je tâtonne.\n",
        chosenRange: 0
      }, {
        "index": "C",
        "label": "Je me fie à mes intuitions ou je demande à un copain de m'aider.\n",
        chosenRange: 0
      }, {
        "index": "D",
        "label": "J'écoute et j'observe attentivement les explications de celui qui s'y connaît\n",
        chosenRange: 0
      }]
    },
    {
      "order": 2,
      "title": "En général, face à un problème,",
      "responses": [{
        "index": "A",
        "label": "Je prends tout mon temps et j'observe.\n",
        chosenRange: 0
      }, {
        "index": "B",
        "label": "J'analyse rationnellement le problème, j'essaie de rester logique...\n",
        chosenRange: 0
      }, {
        "index": "C",
        "label": "Je n'hésite pas, je prends le taureau par les cornes et j'agis.\n",
        chosenRange: 0
      }, {
        "index": "D",
        "label": "Je réagis plutôt instinctivement ; je me fie à mes impressions ou à mes sentiments.\n",
        chosenRange: 0
      }]
    },
    {
      "order": 3,
      "title": "Pour m'orienter dans une ville inconnue,\n",
      "responses": [{
        "index": "A",
        "label": "Je me fie à mon intuition, je \"sens\" la direction générale." +
        " Si cela ne va pas, j'interpelle quelqu'un de sympathique...\n",
        chosenRange: 0
      }, {
        "index": "B",
        "label": "J'observe calmement et attentivement. j'essaie de trouver des points de repère.\n",
        chosenRange: 0
      }, {
        "index": "C",
        "label": "Je me repère rationnellement ; de préférence, je consulte une carte ou un plan.\n",
        chosenRange: 0
      }, {
        "index": "D",
        "label": "L'important pour moi, c'est de réagir rapidement : parfois " +
        "je demande, parfois j'essaie un itinéraire, quitte à faire demi-tour...\n",
        chosenRange: 0
      }]
    },
    {
      "order": 4,
      "title": "Si je dois étudier un cours,\n",
      "responses": [{
        "index": "A",
        "label": "J'essaie surtout de faire des exercices et de découvrir des applications pratiques.\n",
        chosenRange: 0
      }, {
        "index": "B",
        "label": "Je décortique soigneusement la matière : j'analyse et je raisonne.\n",
        chosenRange: 0
      }, {
        "index": "C",
        "label": "Je prends mon temps, je lis et relis attentivement la matière.\n",
        chosenRange: 0
      }, {
        "index": "D",
        "label": "J'aime travailler avec des amis et je m'attache à ce qui me paraît important.\n",
        chosenRange: 0
      }]
    },
    {
      "order": 5,
      "title": "Quand je dois faire un achat important, pour choisir,\n",
      "responses": [{
        "index": "A",
        "label": "J'observe, j'écoute les avis et les contre-avis, je prends tout mon temps.\n",
        chosenRange: 0
      }, {
        "index": "B",
        "label": "Je fais confiance à mon intuition.\n",
        chosenRange: 0
      }, {
        "index": "C",
        "label": "J'essaie de calculer le meilleur rapport qualité-prix (au besoin je consulte une revue spécialisée).\n",
        chosenRange: 0
      }, {
        "index": "D",
        "label": "Ce qui m'intéresse, c'est d'abord de faire un essai, je n'achète pas un chat dans un sac.\n",
        chosenRange: 0
      }]
    },
    {
      "order": 6,
      "title": "Le professeur qui me convient le mieux est quelqu'un\n",
      "responses": [{
        "index": "A",
        "label": "Qui expose sa matière avec rigueur, logique et précision.\n",
        chosenRange: 0
      }, {
        "index": "B",
        "label": "Qui fait agir ses étudiants aussi souvent que possible.\n",
        chosenRange: 0
      }, {
        "index": "C",
        "label": "Qui, avant tout, fait appel à l'expérience vécue des étudiants.\n",
        chosenRange: 0
      }, {
        "index": "D",
        "label": "Qui a le souci de faire observer et réfléchir avant d'agir.\n",
        chosenRange: 0
      }
      ]
    },
    {
      "order": 7,
      "title": "Pour apprendre une langue étrangère, je préfère\n",
      "responses": [{
        "index": "A",
        "label": "A lire et écouter pour bien m'imprégner de la langue.\n",
        chosenRange: 0
      }, {
        "index": "B",
        "label": "Etudier un vocabulaire de base et un minimum de grammaire avant de me lancer dans une conversation.\n",
        chosenRange: 0
      }, {
        "index": "C",
        "label": "Me plonger dans la pratique et parler le plus tôt possible !\n",
        chosenRange: 0
      }, {
        "index": "D",
        "label": "Improviser : tout dépend de la langue, de mes rencontres et de mon état d'esprit...\n",
        chosenRange: 0
      }
      ]
    },
    {
      "order": 8,
      "title": "Pour préparer un exposé, \n",
      "responses": [{
        "index": "A",
        "label": "Je le construis en fonction de mon public. S'il le faut, j'improvise sur place.\n",
        chosenRange: 0
      }, {
        "index": "B",
        "label": "Je répète seul ou en petit comité. \n",
        chosenRange: 0
      }, {
        "index": "C",
        "label": "Je m'inspire d'exemples que j'ai pu observer et apprécier.\n",
        chosenRange: 0
      }, {
        "index": "D",
        "label": "Je construis une structure logique, une analyse et une synthèse.\n",
        chosenRange: 0
      }
      ]
    },
    {
      "order": 9,
      "title": "Pour passer de bonnes vacances, \n",
      "responses": [{
        "index": "A",
        "label": "Je me décide rapidement, je prépare mes bagages ou mon matériel et je fonce.\n",
        chosenRange: 0
      }, {
        "index": "B",
        "label": "Je rassemble une solide documentation, je pèse le pour et " +
        "le contre et je choisis en connaissance de cause...\n",
        chosenRange: 0
      }, {
        "index": "C",
        "label": "J'aime voir sur place et risquer un peu d'imprévu. \n",
        chosenRange: 0
      }, {
        "index": "D",
        "label": "J'hésite à me décider, j'ai besoin d'avis, de témoignages ...\n",
        chosenRange: 0
      }
      ]
    },
    {
      "order": 10,
      "title": "Si je dois lire un livre difficile,\n",
      "responses": [{
        "index": "A",
        "label": "J'analyse la table des matières ... J'essaie d'assimiler chaque élément avant de passer au suivant.\n",
        chosenRange: 0
      }, {
        "index": "B",
        "label": "Je commence par le parcourir pour mieux le \"sentir\" et pour voir si cela vaut la peine d'insister...",
        chosenRange: 0
      }, {
        "index": "C",
        "label": "Je recherche surtout les exemples, les aspects concrets et les applications.\n",
        chosenRange: 0
      }, {
        "index": "D",
        "label": "Je ne me presse pas, je demande parfois des avis, des appréciations.\n",
        chosenRange: 0
      }
      ]
    },
    {
      "order": 11,
      "title": "Si je dois préparer un bon petit plat,\n",
      "responses": [{
        "index": "A",
        "label": "Je m'adresse à quelqu'un qui s'y connaît et je l'observe.\n",
        chosenRange: 0
      }, {
        "index": "B",
        "label": "J'analyse la recette ; il faut de la rigueur et de la précision.\n",
        chosenRange: 0
      }, {
        "index": "C",
        "label": "Je me fie plutôt à mon expérience et à mon coup d'œil ...\n",
        chosenRange: 0
      }, {
        "index": "D",
        "label": "Je me lance, je tâtonne, je goûte ... Je mets tout de suite la main à la pâte.\n",
        chosenRange: 0
      }
      ]
    },
    {
      "order": 12,
      "title": "Pour choisir une profession,\n",
      "responses": [{
        "index": "A",
        "label": "Le mieux c'est d'essayer en faisant un stage.\n",
        chosenRange: 0
      }, {
        "index": "B",
        "label": "Pour moi, le plus important est de se fier à ses intuitions et à ses relations.\n",
        chosenRange: 0
      }, {
        "index": "C",
        "label": "L'idéal est d'observer les professionnels sur le terrain et de solliciter leurs témoignages.\n",
        chosenRange: 0
      }, {
        "index": "D",
        "label": "L'essentiel est d'analyser tous les éléments, par exemple les aptitudes, les débouchés, les salaires...\n",
        chosenRange: 0
      }
      ]
    }
  ];

  testScoreMatrix: Array<TestScoreMatrixItem> = [];
  currentTestItem: TestPsychometriqueItem;
  currentIndex = 0;
  backButtonIsDisabled = false;
  X = 0;
  Y = 0;
  /**
   * @type {number}
   * 0 => Not started
   * 1 => Running
   * 2 => Finished
   */
  testStatus = 0;

  constructor(private userService: UserService) {
  }

  initScoreMatrix() {
    this.testScoreMatrix = [
      {
        index: 1,
        propositionRangeArray: [
          {rangeIndex: "C", chosenRange: 0},
          {rangeIndex: "A", chosenRange: 0},
          {rangeIndex: "B", chosenRange: 0},
          {rangeIndex: "D", chosenRange: 0}
        ]
      },
      {
        index: 2,
        propositionRangeArray: [
          {rangeIndex: "D", chosenRange: 0},
          {rangeIndex: "B", chosenRange: 0},
          {rangeIndex: "C", chosenRange: 0},
          {rangeIndex: "A", chosenRange: 0}
        ]
      },
      {
        index: 3,
        propositionRangeArray: [
          {rangeIndex: "A", chosenRange: 0},
          {rangeIndex: "C", chosenRange: 0},
          {rangeIndex: "D", chosenRange: 0},
          {rangeIndex: "B", chosenRange: 0}
        ]
      },
      {
        index: 4,
        propositionRangeArray: [
          {rangeIndex: "D", chosenRange: 0},
          {rangeIndex: "B", chosenRange: 0},
          {rangeIndex: "A", chosenRange: 0},
          {rangeIndex: "C", chosenRange: 0}
        ]
      },
      {
        index: 5,
        propositionRangeArray: [
          {rangeIndex: "B", chosenRange: 0},
          {rangeIndex: "C", chosenRange: 0},
          {rangeIndex: "D", chosenRange: 0},
          {rangeIndex: "A", chosenRange: 0}
        ]
      },
      {
        index: 6,
        propositionRangeArray: [
          {rangeIndex: "C", chosenRange: 0},
          {rangeIndex: "A", chosenRange: 0},
          {rangeIndex: "B", chosenRange: 0},
          {rangeIndex: "D", chosenRange: 0}
        ]
      },
      {
        index: 7,
        propositionRangeArray: [
          {rangeIndex: "D", chosenRange: 0},
          {rangeIndex: "B", chosenRange: 0},
          {rangeIndex: "C", chosenRange: 0},
          {rangeIndex: "A", chosenRange: 0}
        ]
      },
      {
        index: 8,
        propositionRangeArray: [
          {rangeIndex: "A", chosenRange: 0},
          {rangeIndex: "D", chosenRange: 0},
          {rangeIndex: "B", chosenRange: 0},
          {rangeIndex: "C", chosenRange: 0}
        ]
      },
      {
        index: 9,
        propositionRangeArray: [
          {rangeIndex: "C", chosenRange: 0},
          {rangeIndex: "B", chosenRange: 0},
          {rangeIndex: "A", chosenRange: 0},
          {rangeIndex: "D", chosenRange: 0}
        ]
      },
      {
        index: 10,
        propositionRangeArray: [
          {rangeIndex: "B", chosenRange: 0},
          {rangeIndex: "A", chosenRange: 0},
          {rangeIndex: "C", chosenRange: 0},
          {rangeIndex: "D", chosenRange: 0}
        ]
      },
      {
        index: 11,
        propositionRangeArray: [
          {rangeIndex: "C", chosenRange: 0},
          {rangeIndex: "B", chosenRange: 0},
          {rangeIndex: "D", chosenRange: 0},
          {rangeIndex: "A", chosenRange: 0}
        ]
      },
      {
        index: 12,
        propositionRangeArray: [
          {rangeIndex: "B", chosenRange: 0},
          {rangeIndex: "D", chosenRange: 0},
          {rangeIndex: "A", chosenRange: 0},
          {rangeIndex: "C", chosenRange: 0}
        ]
      }
    ]
  }

  ngOnInit(): void {
    const baseContext = this;
    this.currentTestItem = this.testPsychometriqueContents[0];
    // Wizard examples
    // ------------------------------

    // Basic setup
    /*jQuery(".form-basic").formwizard({
      disableUIStyles: true,
      disableInputFields: false,
      inDuration: 150,
      outDuration: 150
    });
  */
    this.backButtonIsDisabled = true;
    this.updateTestItem();
    this.initScoreMatrix();
    this.busy = this.userService.getUserTestPsychometrique().subscribe(data => {
      this.testStatus = 2;
      this.X = data.x;
      this.Y = data.y;
    });

  }

  initSortableResponse() {
    jQuery('.sort-response').sortable();
  }

  back() {
    if (this.currentIndex === 0) {
      return
    }
    this.currentIndex--;
    this.updateTestItem()
  }

  next() {
    const baseContext = this;
    /*const posIndexs = jQuery('.sort-response').sortable('toArray');
    for (let i = 0; i < posIndexs.length; i++) {
      const indexInSelected = baseContext.currentTestItem.responses.map(
        function (x) {
          return x.index
        }
      ).indexOf(posIndexs[i]);
      baseContext.currentTestItem.responses[indexInSelected].chosenRange = (i + 1);
    }*/

    console.log(this.currentTestItem);

    let isValid = true;
    const validArray = [1, 2, 3, 4];
    const responseArray = [];
    this.currentTestItem.responses.forEach(function (response) {
      responseArray.push(response.chosenRange);
      if (response.chosenRange === 0) {
        isValid = false
      }
    });

    const isMissing = validArray.filter(item => responseArray.indexOf(item) < 0).length > 0;
    if (!isValid) {
      swal({
        title: "Erreur!",
        text: "Vous devez remplir tous les ordres des reponses",
        confirmButtonColor: "#EF5350",
        type: "error"
      });
      return
    }
    if (isMissing) {
      swal({
        title: "Erreur!",
        text: "Vous devez choisir un ordre valide",
        confirmButtonColor: "#EF5350",
        type: "error"
      });
      return
    }
    if (this.currentIndex === this.testPsychometriqueContents.length - 1) {
      this.validateTest();
      return
    }
    this.currentIndex++;
    this.updateTestItem();
    setTimeout(function () {
      // Initialize chart
      donutTweenAnimation('#d3-donut-arc-tween', 60, true);
    }, 200);
  }

  updateTestItem() {
    this.currentTestItem = this.testPsychometriqueContents[this.currentIndex]
    const baseContext = this;
    setTimeout(function () {
      let index = 0;
      baseContext.currentTestItem.responses.forEach(function (response) {
        const constIndex = index;
        /*const select = jQuery(".select-" + baseContext.currentIndex + "-range-" + index);
        // select.select2();
        if (response.chosenRange > 0) {
          select.val(response.chosenRange).trigger("change");
        }
        select.on("change", function () {
          baseContext.currentTestItem.responses[constIndex].chosenRange = +jQuery(this).val()
        });*/

        const radioBox = jQuery('.radio-' + baseContext.currentIndex + '-range-' + index);
        radioBox.uniform({
          radioClass: 'choice'
        });
        radioBox.on('change', function () {
          console.log(jQuery(this).val());
          baseContext.currentTestItem.responses[constIndex].chosenRange = +jQuery(this).val();
        });
        index++;
      });
    }, 200)
  }

  startTest() {
    if (this.testStatus === 2) {
      return
    }
    const baseContext = this;
    this.testStatus = 1;
    this.updateTestItem();
    /*setTimeout(function () {
      baseContext.initSortableResponse();
    }, 20)*/
    setTimeout(function () {
      // Initialize chart
      donutTweenAnimation('#d3-donut-arc-tween', 60);
    }, 200);
  }

  validateTest() {
    const baseContext = this;
    for (let index = 0; index < this.testScoreMatrix.length; index++) {
      this.testScoreMatrix[index].propositionRangeArray.forEach(function (propositionRange) {
        propositionRange.chosenRange = baseContext.testPsychometriqueContents[index].responses.filter(item =>
          item.index === propositionRange.rangeIndex
        )[0].chosenRange
      })
    }
    console.log(this.testPsychometriqueContents);
    console.log(this.testScoreMatrix);
    let I = 0, Ab = 0, Ac = 0, R = 0;
    this.testScoreMatrix.forEach(function (testScoreMatrixItem) {
      I += testScoreMatrixItem.propositionRangeArray[0].chosenRange;
      Ab += testScoreMatrixItem.propositionRangeArray[1].chosenRange;
      Ac += testScoreMatrixItem.propositionRangeArray[2].chosenRange;
      R += testScoreMatrixItem.propositionRangeArray[3].chosenRange;
    });
    this.X = I - Ab - 8;
    this.Y = Ac - R - 5;
    console.log(I)
    console.log(R)
    console.log(Ab)
    console.log(Ac)
    console.log(this.X)
    console.log(this.Y)
    this.busy = this.userService.setUserTestPsychometrique(this.X, this.Y).subscribe(data => {
      this.testStatus = 2;
    }, error => {

    })
  }
}

let foreground;
let arc;
const τ = 2 * Math.PI;
let timerArray = []

// Chart setup
function donutTweenAnimation(element, radius, reset = false) {

  console.log(reset);
  if (!reset) {

    // Basic setup
    // ------------------------------

    // Define main variables


    // Create chart
    // ------------------------------

    // Add SVG element
    const container = d3.select(element).append("svg");

    // Add SVG group
    const svg = container
      .attr("width", radius * 2)
      .attr("height", radius * 2)
      .append("g")
      .attr("transform", "translate(" + radius + "," + radius + ")");


    // Construct chart layout
    // ------------------------------

    // Arc
    arc = d3.svg.arc()
      .outerRadius(radius)
      .innerRadius(radius / 1.4)
      .startAngle(0);


    //
    // Append chart elements
    //

    // Add the background arc, from 0 to 100% (τ).
    const background = svg.append("path")
      .datum({endAngle: τ})
      .style("fill", "#eee")
      .attr("d", arc);

    // Add the foreground arc in orange, currently showing 12.7%.
    foreground = svg.append("path")
      .datum({endAngle: .0 * τ})
      .style("fill", "#7986CB")
      .attr("d", arc);

  }
  timerArray.forEach(function (timer) {
    clearTimeout(timer)
  });
  timerArray = []
  // Start a transition to a new random angle
  for (let sec = 0; sec <= 60; sec++) {
    timerArray.push(setTimeout(function () {
      foreground.transition()
        .duration(0)
        .call(arcTween, sec, foreground);
    }, sec * 1000));
  }


  // Creates a tween on the specified transition's "d" attribute, transitioning
  // any selected arcs from their current angle to the specified new angle.
  function arcTween(transition, sec) {
    if (sec > 50) {
      foreground.style("fill", "#ff0000")
    } else if (sec === 60) {
      foreground.style("fill", "#eee")
    } else {
      foreground.style("fill", "#7986CB")
    }
    const newAngle = ((60 - sec) / 60) * τ;
    transition.attrTween("d", function (d) {

      // Interpolate between the two angles
      const interpolate = d3.interpolate(d.endAngle, newAngle);

      // Return value of the attrTween
      return function (t) {

        // Calculate the current arc angle based on the transition time, t
        d.endAngle = interpolate(t);

        // Lastly, compute the arc path given the updated data
        return arc(d);
      };
    });
  }
}
