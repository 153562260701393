import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {StorageService} from "./storage.service";
import {S_Groupe} from "../models/stage/S_Groupe";
import {Evaluation} from "../models/stage/Evaluation";
import {Session_Stage} from "../models/stage/Session_Stage";
import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {Groupe} from "../models/stage/Groupe";
import {ResultGroupe} from "../models/stage/ResultGroupe";
import {Critere_Etudiant} from "../models/stage/Critere_Etudiant";
import {EvaluationEtudiantRequest} from "../models/stage/EvaluationEtudiantRequest";
import {environment} from "../../../environments/environment";

/**
 * Created by AHMED on 04/08/2017.
 */
@Injectable()
export class StageService extends GenericService {
  context: any;
  evaluation: Evaluation;

  constructor(private http: HttpClient, private stoarageService: StorageService) {
    super();
  }


  getSessionsStage() {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("student-token"));
    const url = environment.baseUrl + "/student/me/session-stage/active";

    return this.http.get<Session_Stage>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }


  getSessionsStageById(sessionStageId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("student-token"));
    const url = environment.baseUrl + "/stage/session-stage/" + sessionStageId;

    return this.http.get<Session_Stage>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  studentChoice(sessionStageId, positionIds: string[], serviceOrders: any[]) {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("student-token"));
    const url = environment.baseUrl + "/stage/session-stage/" + sessionStageId + "/choice";

    return this.http.post(url, {
        groupeOrders: positionIds,
        serviceOrders: serviceOrders
      },
      {
        headers: headers
      }
    ).pipe(
      catchError(this.handleErrors)
    )
  }

  getMultipleChoiceServices(sessionStageId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("student-token"));
    const url = environment.baseUrl + "/stage/session-stage/" + sessionStageId + "/multiple-choices-services";

    return this.http.get<S_Groupe[]>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  studentChoiceService(sessionStageId, sgroupes: S_Groupe[]) {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("student-token"));
    const url = environment.baseUrl + "/stage/session-stage/" + sessionStageId + "/choice-service";

    return this.http.post(url, sgroupes,
      {
        headers: headers
      }
    ).pipe(
      catchError(this.handleErrors)
    )
  }

  getStagesByStudent() {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("student-token"));
    const url = environment.baseUrl + "/student/me/stage/list";

    return this.http.get<Evaluation[]>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  getResultGroupesByStudentAndNiveau() {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("student-token"));
    const url = environment.baseUrl + "/student/me/stage/resultGroupes";

    return this.http.get<ResultGroupe[]>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  generateAttestationStage() {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("student-token"));
    const url = environment.baseUrl + "/student/me/attestation/validation-stage";


    return this.http.get(url, {
      headers: headers,
      responseType: 'blob'
    }).pipe(
      catchError(this.handleErrors)
    );
  }

  getGroupsStudentBySessionStage(sessionStageId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("student-token"));
    const url = environment.baseUrl + "/student/me/stage/groupchoices/" + sessionStageId;

    return this.http.get<Groupe[]>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  getAllCriteresEtudiant() {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("student-token"));
    const url = environment.baseUrl + "/student/me/stage/evaluation/criteres_etudiants";

    return this.http.get<Critere_Etudiant[]>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  submitEvaluationStage(eval_etude: EvaluationEtudiantRequest) {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("student-token"));
    const url = environment.baseUrl + "/student/me/stage/evaluation/submitEvaluationEtudiant";

    return this.http.post<any>(url, eval_etude, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )

  }
}
