import {Component, OnInit} from "@angular/core";
import {Subscription} from "rxjs/Subscription";
import {StageService} from "../../../shared/services/stage.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Session_Stage} from "../../../shared/models/stage/Session_Stage";
import {S_Groupe} from "../../../shared/models/stage/S_Groupe";
import {Service_Stage} from "../../../shared/models/stage/Service_Stage";
import {Groupe} from "../../../shared/models/stage/Groupe";
import {Utils} from "../../../shared/utils";

declare var swal: any;
declare var jQuery;

@Component({
  templateUrl: 'groupe-choice.component.html',
  styleUrls: [],
})
export class GroupeChoiceComponent implements OnInit {

  busy: Subscription;
  sessionStage: Session_Stage = new Session_Stage();
  sessionStageId: number;


  // UI variable
  sgroupes: S_Groupe[] = [];
  services: Service_Stage [] = [];

  groupsStudent: Groupe[] = [];
  isEdited: boolean;

  groupeclassNames: {
    class: string,
    s_groupe_id: number,
    orders?: string[]
  }[] = [];

  ngOnInit() {
    console.log("Session Stage");
    const baseContext = this;
    this.sessionStageId = parseInt(this.route.snapshot.paramMap.get('sessionstageId'));

    this.busy = this.stageService.getSessionsStageById(this.sessionStageId)
      .subscribe(
        (data) => {
          this.sessionStage = data;
        },
        (error) => {

        }
      );

    this.busy = this.stageService.getGroupsStudentBySessionStage(this.sessionStageId)
      .subscribe(
        (data) => {
          if (data.length === 0) {
            this.groupsStudent = this.sessionStage.groupes;
          } else {
            this.groupsStudent = this.sortingDataWithChoice(data);
          }
          setTimeout(function () {
            baseContext.initSortableElements();
          }, 20);
        },
        (error) => {
          this.groupsStudent = this.sessionStage.groupes;

          setTimeout(function () {
            baseContext.initSortableElements();
          }, 20);

        }
      )


  }

  sortingDataWithChoice(groupes: Groupe[]) {

    groupes.sort((a, b) => {
      return a.choices.length === 0 || a.choices.length === 0 || a.choices[0].choice === b.choices[0].choice ? 0 : +(a.choices[0].choice > b.choices[0].choice) || -1;
    });

    groupes.forEach(
      groupe => {
        groupe.sgroupes.forEach(
          sgroupe => {
            sgroupe.sgroupeservice.sort((a, b) => {
              return a.choices.length === 0 || a.choices.length === 0 || a.choices[0].choice === b.choices[0].choice ? 0 : +(a.choices[0].choice > b.choices[0].choice) || -1;
            })
          }
        )
      }
    );

    return groupes;
  }

  submit() {
    const positionStudentIds = jQuery(".sortable_groupe").sortable("toArray");

    for (let i = 0; i < this.groupeclassNames.length; i++) {
      this.groupeclassNames[i].orders = jQuery('.' + this.groupeclassNames[i].class).sortable("toArray");
    }

    console.log(this.groupeclassNames);
    console.log(positionStudentIds);

    this.busy = this.stageService.studentChoice(this.sessionStageId, positionStudentIds, this.groupeclassNames)
      .subscribe(
        (data) => {
          swal({
            title: "Succès!",
            text: "Choix de l'étudiant effectué avec succées",
            confirmButtonColor: "#66BB6A",
            type: "success"
          });
          this.router.navigate(['/inscription/stage/session-stage']);
          console.log("success");
        },
        (error) => {

        }
      )


  }

  initSortableElements() {

    Utils.initSortableElements("sortable_groupe");

    for (let i = 0; i < this.groupsStudent.length; i++) {
      for (let j = 0; j < this.groupsStudent[i].sgroupes.length; j++) {
        if (this.groupsStudent[i].sgroupes[j].sgroupeservice.length > 1) {
          Utils.initSortableElements("sortable_sgroupe_" + this.groupsStudent[i].sgroupes[j].s_groupe_id);
          this.groupeclassNames.push({
            class: "sortable_sgroupe_" + this.groupsStudent[i].sgroupes[j].s_groupe_id,
            s_groupe_id: this.groupsStudent[i].sgroupes[j].s_groupe_id
          });
        }
      }
    }
  }

  constructor(private stageService: StageService, private route: ActivatedRoute,
              private router: Router) {
  }
}
