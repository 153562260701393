import {StorageService} from "app/shared/services/storage.service";
import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {HttpClient} from '@angular/common/http';
import {UserService} from "./user.service";
import {catchError} from "rxjs/operators";
import {environment} from "../../../environments/environment";

@Injectable()
export class OptionalUnitService extends GenericService {

  constructor(private http: HttpClient, private storageService: StorageService, private userService: UserService) {
    super();
  }


  makeStudentChoice(optionalUnitIds: string[]) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));
    const url = environment.baseUrl + "/student/optional-unit/optional_units/choices";

    return this.http.post<any>(url, optionalUnitIds, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  getSessionActiveByStudent() {
    const url = environment.baseUrl + '/student/optional-unit/session-choice/active';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read('student-token'));
    return this.http.get(url, {
        headers: headers
      }
    ).pipe(catchError
      (
        this
          .handleErrors
      )
    )
  }

  inscriptionUO(id_session_choix_ou: number, choices: any[]) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));
    const url = environment.baseUrl + "/student/optional-unit/session-choice/inscription";

    return this.http.post<any>(url, {
      choices: choices,
      sessionId: id_session_choix_ou
    }, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  getStudentsChoices(sessionChoiceId: number) {
    const url = environment.baseUrl + '/student/optional-unit/session-choice/' + sessionChoiceId + '/choices';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read('student-token'));
    return this.http.get(url, {
        headers: headers
      }
    ).pipe(catchError
      (
        this
          .handleErrors
      )
    )
  }

  getChoicesStudent() {
    const url = environment.baseUrl + '/student/optional-unit/choices';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read('student-token'));
    return this.http.get(url, {
        headers: headers
      }
    ).pipe(catchError
      (
        this
          .handleErrors
      )
    )
  }
}
