/**
 * Created by Abbes on 04/08/2017.
 */
import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Student} from "../models/student";
import {StorageService} from "./storage.service";
import {Bac} from "app/shared/models/bac";
import {Fonction} from "../models/fonction";
import {FonctionType} from "../models/FonctionType";
import {Doctaurat} from "../models/doctaurat";
import {Residanat} from "../models/residanat";
import {Specialite} from "../models/specialite";
import {University} from "../models/university";
import {Mention} from "../models/mention";
import {Result} from "../models/result";
import {Service} from "../models/service";
import {Hopital} from "../models/hopital";
import {Level} from "../models/level";
import {Study} from "../models/study";
import {Type} from "../models/type";
import {Credit} from "../models/credit";
import {SectionValidation} from "../models/section-validation";
import {environment} from "../../../environments/environment";

@Injectable()
export class StudentFileService extends GenericService {

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }


  editInformations(student: Student) {
    const url = environment.baseUrl + "/student/edit";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));
    return this.http.put<Student>(url, student,
      {
        headers: headers
      }).pipe(
      catchError(this.handleErrors)
    )
  }

  getAllTypes() {
    const url = environment.baseUrl + "/types";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));

    return this.http.get<Type[]>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  getAllMentions() {
    const url = environment.baseUrl + "/mentions";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));

    return this.http.get<Mention[]>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  editBacInformation(bac: Bac) {
    const url = environment.baseUrl + "/student/me/bac/edit";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));
    return this.http.put<Bac>(url, bac,
      {
        headers: headers
      }).pipe(
      catchError(this.handleErrors)
    )
  }

  getAllResults() {
    const url = environment.baseUrl + "/results";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));

    return this.http.get<Result[]>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  getAllUniversities() {
    const url = environment.baseUrl + "/universities";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));

    return this.http.get<University[]>(url,
      {
        headers: headers
      }).pipe(
      catchError(this.handleErrors)
    )
  }

  getAllLevels() {
    const url = environment.baseUrl + "/levels";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));

    return this.http.get<Level[]>(url,
      {
        headers: headers
      }).pipe(
      catchError(this.handleErrors)
    )
  }

  editStudiesInformation(studies) {
    const url = environment.baseUrl + "/student/me/studies/edit";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));
    return this.http.put<Study[]>(url, studies,
      {
        headers: headers
      }).pipe(
      catchError(this.handleErrors)
    )
  }


  editFonctionInformation(fonctions: Fonction[]) {
    const url = environment.baseUrl + "/student/edit-fonctions";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));
    return this.http.put<Fonction[]>(url, fonctions, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  editDoctaurat(doctaurat: Doctaurat) {
    const url = environment.baseUrl + "/student/me/doctaurat/edit";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));
    return this.http.put<Student>(url, doctaurat,

      {
        headers: headers
      }).pipe(
      catchError(this.handleErrors)
    )
  }

  getAllSpecialities() {
    const url = environment.baseUrl + "/specialities";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));

    return this.http.get<Specialite[]>(url,
      {
        headers: headers
      }).pipe(
      catchError(this.handleErrors)
    )
  }

  getAllHopitaux() {
    const url = environment.baseUrl + "/hopitaux";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));

    return this.http.get<Hopital[]>(url,
      {
        headers: headers
      })
      .pipe(
        catchError(this.handleErrors)
      )
  }

  getAllServices() {
    const url = environment.baseUrl + "/services";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));

    return this.http.get<Service[]>(url,
      {
        headers: headers
      })
      .pipe(
        catchError(this.handleErrors)
      )
  }

  editResidanatInformation(residanat: Residanat) {
    const url = environment.baseUrl + "/student/edit-residanat";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));
    return this.http.put<Residanat>(url, residanat, {
      headers: headers
    })
      .pipe(
        catchError(this.handleErrors)
      )
  }

  submitAdmin(sectionIds: number[]) {
    const url = environment.baseUrl + "/student/me/submitForReview";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));
    return this.http.post<SectionValidation[]>(url, sectionIds,
      {
        headers: headers
      }
    )
      .pipe(
        catchError(this.handleErrors)
      )
  }

  getAllCredits() {
    const url = environment.baseUrl + "/credits";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));

    return this.http.get<Credit[]>(url,
      {
        headers: headers
      })
      .pipe(
        catchError(this.handleErrors)
      )
  }

  removeDoctaurat() {
    const url = environment.baseUrl + "/student/me/doctaurat/remove";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));
    return this.http.delete<Student>(url,
      {
        headers: headers
      })
      .pipe(
        catchError(this.handleErrors)
      )
  }

  removeResidant() {
    const url = environment.baseUrl + "/student/me/residanat/remove";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));
    return this.http.delete<Student>(url,
      {
        headers: headers
      })
      .pipe(
        catchError(this.handleErrors)
      )
  }

  getAllFonctionTypes() {
    const url = environment.baseUrl + "/fonction_types";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));

    return this.http.get<FonctionType[]>(url, {
      headers: headers
    })
      .pipe(
        catchError(this.handleErrors)
      )
  }

  generationAttestationFr() {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("student-token"));
    const url = environment.baseUrl + "/student/me/attestation-inscription/fr";

    return this.http.post(url, {}, {
      headers: headers,
      responseType: 'blob'
    })
      .pipe(
        catchError(this.handleErrors)
      )
  }
}
