import {Component, OnInit} from "@angular/core";
import {Student} from "../../shared/models/student";
import {UserService} from "../../shared/services/user.service";
import {Subscription} from "rxjs/Subscription";
import {RegistrationYearUniversity} from "../../shared/models/RegistrationYearUniversity";
import {Utils} from "../../shared/utils";
import {Level} from "../../shared/models/level";
import {PartielNotificationService} from "../../shared/services/partielNotification.service";
import {StorageService} from "../../shared/services/storage.service";
import {Specialite} from "../../shared/models/specialite";
import {StudentFileService} from "../../shared/services/student-file.service";
import {environment} from "../../../environments/environment";


declare let swal: any;
declare let jQuery;

@Component({
  templateUrl: 'inscriptions-university-year.component.html',
  styleUrls: [],
})
export class InscriptionsUniversityYearComponent implements OnInit {
  busy: Subscription;

  informationInscriptionYear: InformationInscriptionYear = new InformationInscriptionYear();
  loggedUser: Student = new Student();
  level: Level = new Level();
  paymentMedia: string;
  specialities: Specialite[] = [];
  selectedSpecialityId: number;
  submitted: boolean;

  rang: number;

  ngOnInit() {
    this.loggedUser = this.userService.loggedUser;
    const cycle: number = Utils.getCycleByLevel(this.loggedUser.id_level);
    this.level.id_level = this.loggedUser.id_level;
    this.level.label = Utils.getLabelNiveau(this.level.id_level);
    this.busy = this.userService.getInscriptionByStudent(
      Utils.getCurrentUniversityYear(cycle),
      cycle,
      this.loggedUser.id_student)
      .subscribe(
        (data: InformationInscriptionYear) => {
          this.informationInscriptionYear = data;
          console.log(Utils.getCurrentUniversityYear(cycle, 0, cycle === 1 ? 1 : 0));
          this.rang = Utils.getRangPerYear(this.loggedUser.studies, Utils.getCurrentUniversityYear(cycle, 0, cycle === 1 ? 1 : 0));
        }
      );

    this.partielNotificationService.viewSubsection(1)
      .subscribe(
        (data) => {
        }
      );

    this.initRegistrationPaymentMedia();
    this.getAllSpecialities();
    const specialite = jQuery(".select-specialite");
    specialite.select2();
  }

  private getAllSpecialities() {
    this.busy = this.studentFileService.getAllSpecialities()
      .subscribe(
        (data) => {
          this.specialities = data;
          this.initSpecialitySelect();
        }
      )
  }

  initSpecialitySelect() {
    console.log('initSpecialitySelect');
    const specialite = jQuery(".select-specialite");
    const baseContext = this;
    specialite.select2();
    setTimeout(function () {
        specialite.select2();
        console.log('init select');
        specialite.on("change", function () {
          baseContext.selectedSpecialityId = +specialite.val();
          console.log(baseContext.selectedSpecialityId);
        });
      }, 2000
    )
    ;
  }

  inscrireYearUniversity() {
    this.submitted = true;

    console.log(this.selectedSpecialityId);
    if (this.level.id_level > 7 && !this.selectedSpecialityId) {
      return;
    }
    if (!this.paymentMedia) {
      swal({
        title: "Erreur!",
        text: "Vous devez uploader votre reçu de paiement",
        confirmButtonColor: "#EF5350",
        type: "error"
      });
      return;
    }
    jQuery("#modal_form_vertical").modal('toggle');
    const baseContext = this;
    this.busy = this.userService.inscrireYearUniversity(
      this.informationInscriptionYear.inscription.id_Registration_University, this.paymentMedia,
      this.selectedSpecialityId)
      .subscribe(
        (data) => {
          this.informationInscriptionYear.statusInscription = 3;
          swal({
            title: "Succès!",
            text: "Inscription de l'année universitaire est " +
              baseContext.informationInscriptionYear.inscription.year_university + "éffectué avec succées",
            confirmButtonColor: "#66BB6A",
            type: "success"
          });
        },
        (error) => {
          if (error.status === 402) {
            swal({
              title: "Erreur !",
              text: "Votre dossier n'est pas encore validé",
              confirmButtonColor: "#66BB6A",
              type: "error"
            });
          }
        }
      )

  }

  constructor(private userService: UserService, private storageService: StorageService,
              private studentFileService: StudentFileService,
              private partielNotificationService: PartielNotificationService) {

  }

  private initRegistrationPaymentMedia() {
    Utils.initializeUploadFile(environment.baseUrl + "/student/" + this.userService.loggedUser.id_student + "/registration/payment/media/upload",
      this.userService.getTokent(), ".file-input-student-registration-payment-media", 1, [], [], ['png', 'pdf', 'jpeg', 'jpg']);
    const baseContext = this;
    jQuery('.file-input-student-registration-payment-media').change(function () {
      console.log('file input change');
    }).on('fileuploaded', function (event, data, previewId, index) {
      baseContext.paymentMedia = data.response.media.path;
      console.log("fileuploaded");
      swal({
        title: "Succés!",
        text: 'Vous avez ajouté une photo de votre reçu de paiement',
        confirmButtonColor: "#66BB6A",
        type: "success"
      });
    }).on('filedeleted', function (event, key, jqXHR, data) {
      baseContext.paymentMedia = null;
      swal({
        title: "Succés!",
        text: 'Vous avez supprimé la photo de votre reçu de paiement',
        confirmButtonColor: "#66BB6A",
        type: "success"
      });
    });
  }
}

export class InformationInscriptionYear {
  public inscription: RegistrationYearUniversity = new RegistrationYearUniversity();
  public statusInscription: number; // 0 : Pas Encore ouverte , 1 :Inscription Ouverte , 2 : Inscription Fermée , 3 : Déja inscrit
}
