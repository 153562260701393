import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {StorageService} from "./storage.service";
import {Evaluation} from "../models/stage/Evaluation";
import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {MoyenneSession} from "../models/direction_etudes/MoyenneSession";
import {environment} from "../../../environments/environment";

/**
 * Created by AHMED on 04/08/2017.
 */
@Injectable()
export class SessionExamenService extends GenericService {
  context: any;
  evaluation: Evaluation;

  constructor(private http: HttpClient, private stoarageService: StorageService) {
    super();
  }


  getMoyennesSessionsExamensByStudent() {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("student-token"));
    const url = environment.baseUrl + "/student/me/sessionExamen/moyennesSessionsByEtudiant";

    return this.http.get<MoyenneSession[]>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }


}
