import {StorageService} from "app/shared/services/storage.service";
import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {UserService} from "./user.service";
import {Notification} from "../models/notification";
import {environment} from "../../../environments/environment";

@Injectable()
export class NotificationService extends GenericService {

  isNavCollapsed = false;

  constructor(private http: HttpClient, private storageService: StorageService, private userService: UserService) {
    super();
  }


  getAllStudentNotifications() {
    const url = environment.baseUrl + '/notification/student';
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.get<Notification[]>(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  getNotificationById(id_notification: number) {
    const url = environment.baseUrl + '/notification/' + id_notification;
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.get(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  setNotficationViewed(id_notification: number) {
    const url = environment.baseUrl + '/notification/' + id_notification + '/student/' + this.userService.loggedUser.id_student + '/viewed';
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.put(url, {}, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }
}
