import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenuComponent} from "./menu/menu.component";
import {StyleCognitifComponent} from "./style-cognitif/style-cognitif.component";
import {SharedModule} from "../shared/shared.module";
import {PortfolioModuleRouting} from "./portfolio.routing";
import { ResultMasteresComponent } from './result-masteres/result-masteres.component';
import { ResultStagesComponent } from './result-stages/result-stages.component';
import { DetailsResultStageComponent } from './details-result-stage/details-result-stage.component';
import { ResultEcosComponent } from './result-ecos/result-ecos.component';
import { ResultSessionExamensComponent } from './result-session-examens/result-session-examens.component';
import { ResultUoComponent } from './result-uo/result-uo.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PortfolioModuleRouting
  ],
  declarations: [
    MenuComponent,
    StyleCognitifComponent,
    ResultMasteresComponent,
    ResultStagesComponent,
    DetailsResultStageComponent,
    ResultEcosComponent,
    ResultSessionExamensComponent,
    ResultUoComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PortfolioModule {
}
