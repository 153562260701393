export class Teacher {
  public id_Teacher: number;
  public first_name: string;
  public last_name: string;
  public second_name ?: string;
  public nom_de_famille?: string;
  public sex: string;

}

export class Enseignant {
  public nom: string;
  public prenom: string;
}
