import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
// Layouts
import {FullLayoutComponent} from "./layouts/full-layout.component";
import {LoginComponent} from "./login/login.component";
import {StudentRegistrationComponent} from "./registration/student/student-registration.component";
import {NotFoundComponent} from "./error/not-found.component";
import {EnseignementModule} from "./enseignement/enseignement.module";
import {ManageStudentFileModule} from "./student-file/student-file.module";
import {SupportModule} from "./support/support.module";
import {InscriptionsModule} from "./inscriptions/inscriptions.module";
import {ErrorModule} from "./error/error.module";
import {TestPsychometriqueComponent} from "./test-psychometrique/test-psychometrique.component";
import {PortfolioModule} from "./portfolio/portfolio.module";
import {EvaluationsModule} from "./evaluations/evaluations.module";

export function loadManageStudentFileModule() {
  return ManageStudentFileModule
}

export function loadSupportModule() {
  return SupportModule
}

export function loadInscriptionsModule() {
  return InscriptionsModule
}

export function loadEvaluationsModule() {
  return EvaluationsModule
}

export function loadEnseignementModule() {
  return EnseignementModule
}

export function loadPortfolioModule() {
  return PortfolioModule
}

export function loadErrorModule() {
  return ErrorModule
}

export const routes: Routes = [
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: loadManageStudentFileModule
      },
      {
        path: 'student-file',
        loadChildren: loadManageStudentFileModule
      },
      {
        path: 'support',
        loadChildren: loadSupportModule
      },
      {
        path: 'inscription',
        loadChildren: loadInscriptionsModule
      },
      {
        path: 'enseignement',
        loadChildren: loadEnseignementModule
      },
      {
        path: 'portfolio',
        loadChildren: loadPortfolioModule
      },
      {
        path: 'error',
        loadChildren: loadErrorModule
      },
      {
        path: 'test-psychometrique',
        component: TestPsychometriqueComponent
      },
      {
        path: 'evaluations',
        loadChildren: loadEvaluationsModule
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'nmstudent-registration',
    component: StudentRegistrationComponent
  },
  {
    path: 'student-registration',
    component: StudentRegistrationComponent
  },

  {path: '**', component: NotFoundComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
