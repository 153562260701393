import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-ng-emogi',
  templateUrl: './ng-emogi.component.html',
  styleUrls: ['./ng-emogi.component.css']
})
export class NgEmogiComponent implements OnInit {

  @Input()
  value ?: number;

  @Input()
  signification?: string;

  @Input()
  index ?: number;

  constructor() {
  }

  ngOnInit() {
  }

}
