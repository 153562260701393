import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {StageService} from "../../shared/services/stage.service";
import {Subscription} from "rxjs";
import {Critere_Etudiant} from "../../shared/models/stage/Critere_Etudiant";
import {Evaluation_Critere_Etudiant} from "../../shared/models/stage/Evaluation_Critere_Etudiant";
import {EvaluationEtudiantRequest} from "../../shared/models/stage/EvaluationEtudiantRequest";

declare let swal: any;

@Component({
  selector: 'app-evaluation-stage',
  templateUrl: './evaluation-stage.component.html',
  styleUrls: ['./evaluation-stage.component.css']
})
export class EvaluationStageComponent implements OnInit {
  evaluation_id: number;
  busy: Subscription;
  criteres_etudiants: Critere_Etudiant[] = [];
  evaluations_criteres_etudiants: Evaluation_Critere_Etudiant[] = [];
  commentaire: string;

  constructor(private router: Router, private route: ActivatedRoute, private stageService: StageService) {
  }

  ngOnInit() {
    this.evaluation_id = parseInt(this.route.snapshot.paramMap.get('evaluation_id'), 10);
    this.getAllCriteresEtudiant();
  }

  private getAllCriteresEtudiant() {
    this.busy = this.stageService.getAllCriteresEtudiant().subscribe((data) => {
      this.criteres_etudiants = data;
    })
  }

  setValue(cr: Critere_Etudiant, number: number) {
    cr.value = number;
    console.log(cr);
  }

  submit() {
    if (this.criteres_etudiants.find((one) => {
      return one.value == null
    }) != null)
    {swal({
      title: "Echec!",
      text: "Veuillez répondre à tous les critères.",
      type: "error"
    }, function (confirm) {
      if (confirm) {
      }
    });
      return ;

    }
    this.evaluations_criteres_etudiants = [];
    for (const critere of this.criteres_etudiants) {
      const ece = new Evaluation_Critere_Etudiant();
      ece.critere_etudiant_id = critere.critere_etudiant_id;
      ece.value = critere.value;
      ece.evaluation_id = this.evaluation_id;
      this.evaluations_criteres_etudiants.push(ece);
    }
    const baseContext = this;
    const eval_etud = new EvaluationEtudiantRequest();
    eval_etud.evaluation_id = this.evaluation_id;
    eval_etud.commentaire = this.commentaire;
    eval_etud.evaluations_criteres_etudiants = this.evaluations_criteres_etudiants;
    this.busy = this.stageService.submitEvaluationStage(eval_etud).subscribe((data) => {
      swal({
        title: "Succès!",
        text: "Votre évaluation a été soumise avec succès"
      }, function (confirm) {
        if (confirm) {
          baseContext.router.navigateByUrl('/enseignement/stage');
        }
      });
    }, (error) => {
      swal('Erreur', 'Une erreur est survenue.', 'error')
    })
    ;
  }
}

