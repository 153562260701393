import {Component, OnInit} from "@angular/core";
import {Student} from "../../shared/models/student";
import {UserService} from "../../shared/services/user.service";
import {StudentFileService} from "../../shared/services/student-file.service";
import {Subscription} from "rxjs/Subscription";
import {Utils} from "../../shared/utils";
import {SectionValidation} from "app/shared/models/section-validation";
import {StorageService} from "../../shared/services/storage.service";
import {Router} from "@angular/router";

declare let jQuery: any;
declare let swal: any;

@Component({
  templateUrl: 'dashboard-student-file.component.html',
  styleUrls: [],
})
export class DashboardStudentFileComponent implements OnInit {

  student: Student;
  progress = 25;
  busy: Subscription;
  globalStatus: string;

  ngOnInit() {
    console.log("Ng OnInit");
    const baseContext = this;
    this.student = this.getLoggedUser();
    this.student.isNew = Utils.verifyNewStudent(this.student.study_access_year);
    this.userService.loggedUser = this.student;
    console.log(this.student);

    // Basic example
    this.calculProgress();
    this.globalStatus = this.getStatus(this.student.dossier_status, this.student.administration_review);
  }


  constructor(private userService: UserService,
              private router: Router,
              private studentFileService: StudentFileService,
              private stoarageService: StorageService) {

  }

  calculProgress() {
    if (this.student.bac) {
      this.progress += 25;
    }
    if ((this.student.studies && this.student.studies.length) || this.student.isNew) {
      this.progress += 25;
    }
    if (this.student.img) {
      this.progress += 25;
    }
  }

  private getLoggedUser() {
    return this.userService.loggedUser;
  }

  submitAdmin() {
    const sectionIds = this.getSectionIds(this.getSections());
    this.busy = this.studentFileService.submitAdmin(sectionIds)
      .subscribe(
        (data) => {
          this.userService.loggedUser.dossier_status = 1;
          this.globalStatus = this.getStatus(1, this.student.administration_review);
          this.userService.loggedUser.validations = data;
          this.stoarageService.write("student", this.userService.loggedUser);
          swal({
            title: "Succès!",
            text: 'Dossier envoyé avec succées',
            confirmButtonColor: "#66BB6A",
            type: "success"
          });
        },
        (error) => {

        }
      )
  }

  getSections() {
    const sections: Section[] = [];


    if (this.getLoggedUser().administration_review !== 1) {
      let section: Section;
      let sectionTmp: Section;
      let status: number;
      // General Informations
      section = new Section();
      section.id = 1;
      section.name = "Information générale";
      section.url = "/student-file/general/edit";
      status = this.getStatusSection(this.getLoggedUser().first_name, 1,
        this.getLoggedUser().validations);
      sectionTmp = this.settingFromStatus(section, status);
      sections.push(sectionTmp);
      // Bac
      section = new Section();
      section.id = 2;
      section.name = "Baccalauréat";
      section.url = "/student-file/bac-info";
      status = this.getStatusSection(this.getLoggedUser().bac, 2,
        this.getLoggedUser().validations);
      sectionTmp = this.settingFromStatus(section, status);
      sections.push(sectionTmp);

      if (!this.getLoggedUser().isNew) {
        // Studies
        section = new Section();
        section.id = 3;


        section.name = this.student.medecin === 0 ? "Etudes de base antérieures" : "Etudes médicales de base antérieures";
        /*
         (Il faut inscrire toutes les années d'études déjà accomplies jusqu'à l'internat (Internat)
         */
        section.url = "/student-file/studies";
        if (!this.getLoggedUser().studies) {
          status = this.getStatusSection(null, 3,
            this.getLoggedUser().validations);
        } else {
          status = this.getStatusSection(this.getLoggedUser().studies.length === 0 ? null : "", 3,
            this.getLoggedUser().validations);
        }
        sectionTmp = this.settingFromStatus(section, status);
        sections.push(sectionTmp);
      }

      // Photo
      section = new Section();
      section.id = 4;
      section.name = "Photos";
      section.url = "/student-file/upload-photo";
      status = this.getStatusSection(this.getLoggedUser().img, 4,
        this.getLoggedUser().validations);
      sectionTmp = this.settingFromStatus(section, status);
      sections.push(sectionTmp);

      if (this.student.medecin) {
        // Fonctions(Optionnel)
        section = new Section();
        section.id = 5;
        section.name = "Fonctions(Optionnel)";
        section.url = "/student-file/fonction";
        if (!this.getLoggedUser().fonctions) {
          status = this.getStatusSection(null, 5,
            this.getLoggedUser().validations
          );
        } else {
          status = this.getStatusSection(this.getLoggedUser().fonctions.length === 0 ? null : "", 5,
            this.getLoggedUser().validations);
        }

        sectionTmp = this.settingFromStatus(section, status);
        sections.push(sectionTmp);
      }
      if (this.student.medecin) {
        // Doctorat(Optionnel)(Optionnel)
        section = new Section();
        section.id = 6;
        section.name = "Diplôme de docteur en médecine (Optionnel)";
        section.url = "/student-file/doctaurat";
        status = this.getStatusSection(this.getLoggedUser().doctaurat, 6,
          this.getLoggedUser().validations);
        sectionTmp = this.settingFromStatus(section, status);
        sections.push(sectionTmp);
      }
      // Fonctions(Optionnel)

      if (this.student.medecin) {
        section = new Section();
        section.id = 7;
        section.name = "Residanat (Optionnel)";
        section.url = "/student-file/residanat";
        status = this.getStatusSection(this.getLoggedUser().residanat, 7,
          this.getLoggedUser().validations);
        sectionTmp = this.settingFromStatus(section, status);
        sections.push(sectionTmp);
      }
    } else {
      this.router.navigate(['/test-psychometrique']);
    }
    return sections;
  }

  private settingFromStatus(section: Section, status: number) {

    switch (status) {
      case 1 : {
        section.imgIcon = "icon-plus3";
        section.imgStyling = "border-brown-600 text-brown-600";
        section.progressLabel = "Vide";
        section.btnLabel = "Ajouter";
        section.btnIcon = "icon-plus2";
        break
      }
      case 2 : {
        section.imgIcon = "icon-file-download2";
        section.imgStyling = "border-primary text-primary";
        section.progressLabel = "Enregistré";
        section.btnLabel = "Modifier";
        section.btnIcon = "icon-pen2";
        break
      }
      case 3 : {
        section.imgIcon = "icon-spinner9";
        section.imgStyling = "border-primary text-primary";
        section.progressLabel = "Envoyé";
        section.btnLabel = "Modifier";
        section.btnIcon = "icon-pen2";
        break
      }
      case 4 : {
        section.imgIcon = "icon-hour-glass";
        section.imgStyling = "border-primary text-primary";
        section.progressLabel = "En cour de taitement";
        section.btnLabel = "Modifier";
        section.btnIcon = "icon-pen2";
        break
      }
      case 5 : {
        section.imgIcon = "icon-checkmark";
        section.imgStyling = "border-success text-success";
        section.progressLabel = "Validé";
        section.btnLabel = "Modifier";
        section.btnIcon = "icon-pen2";
        break
      }
      case 6 : {
        section.imgIcon = "icon-warning22";
        section.imgStyling = "border-warning-300 text-warning-300";
        section.progressLabel = "A réviser";
        section.btnLabel = "Modifier";
        section.btnIcon = "icon-pen2";
        break
      }
      case 7 : {
        section.imgIcon = "icon-cross";
        section.imgStyling = "border-danger text-danger";
        section.progressLabel = "Refusé";
        section.btnLabel = "Modifier";
        section.btnIcon = "icon-pen2";
        break
      }
    }
    section.status = status;
    return section;

  }


  public getStatus(dossier_status: number, valid_from_administration: number) {

    if (dossier_status === 0) {
      return "Pas encore envoyé"; // Stored
    } else if (dossier_status === 1) {
      return "Envoyé"; // Submit
    } else if (dossier_status === 2) {
      return "A réviser"; // In review
    } else {
      if (valid_from_administration === 1) {
        return "Validé"; // Accepted
      } else if (valid_from_administration === 2) {
        return "A réviser"; // Reviewed
      } else {
        return "Refusé"; // Refused
      }
    }

  }

  private getStatusSection(object: any, sectionId: number,
                           validations: SectionValidation[]) {

    if (object == null) {
      return 1; // Empty
    } else {
      const statusSection = Utils.getStatusSection(validations, sectionId);
      if (statusSection === null) {
        return 2; // Enregistré
      } else {
        if (statusSection.status === 0) {
          return 3; // Envoyé
        } else if (statusSection.status === 1) {
          return 5; // Valid
        } else if (statusSection.status === 2) {
          return 6; // Reviewed
        } else {
          return 7; // Refused
        }
      }
    }
  }

  private getSectionIds(sections: Section[]) {
    const sectionIds: number[] = [];

    sections.forEach(section => {
      if (section.status === 2 || section.status === 3 || section.status === 6) {
        sectionIds.push(section.id);
      }
    });

    return sectionIds;
  }

  public openSection(section: Section) {
    if (section.status === 5) {
      swal({
        title: "Non autorisé!",
        text: 'Votre dossier est validé, vous ne pouvez plus le modifier. Veuillez réclamer votre besoin.',
        type: "error"
      });
      return;
    }
    this.router.navigateByUrl(section.url);
  }
}

export class Section {
  public id: number;
  public url: string;
  public imgStyling: string;
  public imgIcon: string;
  public progressLabel: string;
  public name: string;
  public btnLabel: string;
  public btnIcon: string;
  public status: number;

}


