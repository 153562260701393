/**
 * Created by Abbes on 04/08/2017.
 */
import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {DashboardStudentFileComponent} from "./dashboard/dashboard-student-file.component";
import {StudentRegistrationComponent} from "../registration/student/student-registration.component";
import {BacInfoComponent} from "./bac-info/bac-info.component";
import {StudiesStudentFileComponent} from "./studies/studies-student-file.component";
import {PhotoComponent} from "./photo/photo.component";
import {FonctionStudentFileComponent} from "./fonction/fonction-student-file.component";
import {DoctauratStudentFileComponent} from "./doctaurat/doctaurat-student-file.component";
import {ResidanatStudentFileComponent} from "./residanat/residanat-student-file.component";

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        component: DashboardStudentFileComponent,
        path: ''
      },
      {
        path: '',
        children: [
          {
            path: 'general/edit',
            component: StudentRegistrationComponent
          },
          {
            path: 'bac-info',
            component: BacInfoComponent
          },
          {
            path: 'studies',
            component: StudiesStudentFileComponent
          },
          {
            path: 'upload-photo',
            component: PhotoComponent
          },
          {
            path: 'fonction',
            component: FonctionStudentFileComponent
          },
          {
            path: 'doctaurat',
            component: DoctauratStudentFileComponent
          },
          {
            path: 'residanat',
            component: ResidanatStudentFileComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageStudentFileRouting {
}
